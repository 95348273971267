package service.poker

import io.ktor.client.plugins.websocket.*
import service.AbstractSessionService
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerClientEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerClientSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerTableCommand
import studio.lostjoker.smartdealer.gameplay.protocol.converter.toDomain
import studio.lostjoker.smartdealer.gameplay.protocol.converter.toProtocol
import kotlin.uuid.Uuid


class TableDeviceSessionService(
    gameplayBaseUrl: String,
    val id: Uuid = Uuid.random(),
    private val gameId: Uuid,
    playerId: String,
) : AbstractSessionService<PokerClientEvent, PokerTableCommand, PokerClientSnapshot>(
    "$gameplayBaseUrl/api/poker/v1/games/$gameId/players/$playerId/table-devices/$id",
) {
    override suspend fun receiveSnapshot(): PokerClientSnapshot {
        return session.receiveDeserialized<protocol.poker.PokerClientSnapshot>().toDomain()
    }

    override suspend fun receiveEvent(): PokerClientEvent {
        return session.receiveDeserialized<protocol.poker.PokerClientEvent>().toDomain()
    }

    override suspend fun sendCommand(command: PokerTableCommand) {
        session.sendSerialized(command.toProtocol())
    }

    override fun toString(): String {
        return "TableDeviceSessionService(id='$id', gameId='$gameId')"
    }
}

