package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.displayCutout
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Help
import androidx.compose.material.icons.automirrored.filled.Logout
import androidx.compose.material.icons.automirrored.filled.MenuBook
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material.icons.filled.HourglassTop
import androidx.compose.material.icons.filled.Tune
import androidx.compose.material3.DrawerState
import androidx.compose.material3.Icon
import androidx.compose.material3.ModalDrawerSheet
import androidx.compose.material3.ModalNavigationDrawer
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.logo_long_text_horizontal_black
import smartdealer.appshared.generated.resources.logo_long_text_horizontal_white
import smartdealer.appshared.generated.resources.poker_chip
import smartdealer.appshared.generated.resources.poker_game_navigation_drawer_help
import smartdealer.appshared.generated.resources.poker_game_navigation_drawer_gameplay_tutorial
import smartdealer.appshared.generated.resources.poker_game_navigation_drawer_leave
import smartdealer.appshared.generated.resources.poker_game_navigation_drawer_sit_out
import smartdealer.appshared.generated.resources.poker_game_navigation_drawer_terminate
import smartdealer.appshared.generated.resources.poker_game_navigation_drawer_top_up
import smartdealer.appshared.generated.resources.poker_game_navigation_drawer_update_settings
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.poker.enum.GameFormat
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import studio.lostjoker.smartdealer.ui.theme.darkColorScheme

@Composable
fun NavigationDrawer(
    drawerState: DrawerState,
    device: Device = Device.Player,
    gameFormat: GameFormat = GameFormat.SitAndGo,
    onClickSitOut: () -> Unit = {},
    onClickTopUp: () -> Unit = {},
    onClickTutorial: () -> Unit = {},
    onClickHelp: () -> Unit = {},
    onClickUpdateTournamentSettings: () -> Unit = {},
    onClickUpdateRingGameSettings: () -> Unit = {},
    onClickTerminateGame: () -> Unit = {},
    onClickLeaveGame: () -> Unit = {},
    host: Boolean = false,
    away: Boolean = false,
) {
    val items = buildList {
        if (device == Device.Player) {
            if (!away) {
                add(
                    NavigationItems(
                        title = Title.SitOut,
                        imageVector = Icons.Default.HourglassTop,
                    ),
                )
            }
            if (gameFormat == GameFormat.RingGame) {
                add(
                    NavigationItems(
                        title = Title.TopUp,
                        painter = painterResource(Res.drawable.poker_chip),
                    )
                )
            }
            add(
                NavigationItems(
                    title = Title.Tutorial,
                    imageVector = Icons.AutoMirrored.Default.MenuBook,
                )
            )
            add(
                NavigationItems(
                    title = Title.Help,
                    imageVector = Icons.AutoMirrored.Default.Help,
                )
            )
        }
        if (host) {
            add(
                NavigationItems(
                    title = Title.UpdateSettings,
                    imageVector = Icons.Filled.Tune,
                )
            )
        }
        if (gameFormat == GameFormat.RingGame) {
            add(
                NavigationItems(
                    title = Title.TerminateGame,
                    imageVector = Icons.Filled.CheckCircle,
                )
            )
        }
        add(
            NavigationItems(
                title = Title.LeaveGame,
                imageVector = Icons.AutoMirrored.Default.Logout,
            )
        )
    }

    ModalNavigationDrawer(
        drawerState = drawerState,
        drawerContent = {
            ModalDrawerSheet(
                modifier = Modifier.fillMaxWidth(0.85f),
                windowInsets = WindowInsets.displayCutout,
            ) {
                Image(
                    modifier = Modifier.fillMaxWidth(),
                    painter = if (PokerAppTheme.colors == darkColorScheme) {
                        painterResource(Res.drawable.logo_long_text_horizontal_white)
                    } else {
                        painterResource(Res.drawable.logo_long_text_horizontal_black)
                    },
                    contentDescription = null,
                    contentScale = ContentScale.Fit,
                )
                items.forEachIndexed { _, item ->
                    NavigationDrawerItem(
                        label = { Text(text = stringResource(item.title.resource)) },
                        selected = false,
                        onClick = {
                            when (item.title) {
                                Title.SitOut -> onClickSitOut()
                                Title.TopUp -> onClickTopUp()
                                Title.Tutorial -> onClickTutorial()
                                Title.Help -> onClickHelp()
                                Title.UpdateSettings -> when (gameFormat) {
                                    GameFormat.SitAndGo -> onClickUpdateTournamentSettings()
                                    GameFormat.RingGame -> onClickUpdateRingGameSettings()
                                }
                                Title.TerminateGame -> onClickTerminateGame()
                                Title.LeaveGame -> onClickLeaveGame()
                            }
                        },
                        icon = {
                            item.imageVector?.let {
                                Icon(
                                    imageVector = item.imageVector,
                                    contentDescription = stringResource(item.title.resource)
                                )
                            }
                            item.painter?.let {
                                Icon(
                                    painter = item.painter,
                                    contentDescription = stringResource(item.title.resource)
                                )
                            }
                        },
                        modifier = Modifier
                            .padding(NavigationDrawerItemDefaults.ItemPadding),
                    )
                }
            }
        },
        gesturesEnabled = true,
    ) {

    }
}

data class NavigationItems(
    val title: Title,
    val imageVector: ImageVector? = null,
    val painter: Painter? = null,
)

enum class Title(val resource: StringResource) {
    SitOut(Res.string.poker_game_navigation_drawer_sit_out),
    TopUp(Res.string.poker_game_navigation_drawer_top_up),
    Tutorial(Res.string.poker_game_navigation_drawer_gameplay_tutorial),
    Help(Res.string.poker_game_navigation_drawer_help),
    UpdateSettings(Res.string.poker_game_navigation_drawer_update_settings),
    TerminateGame(Res.string.poker_game_navigation_drawer_terminate),
    LeaveGame(Res.string.poker_game_navigation_drawer_leave),
}
