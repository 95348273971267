package studio.lostjoker.smartdealer.helpers

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import service.RetryableSessionException
import service.SessionService
import kotlin.math.max
import kotlin.math.pow
import kotlin.time.Duration.Companion.seconds

internal fun <E, C, S> CoroutineScope.connectSessionService(
    sessionService: SessionService<E, C, S>,
    eventReducer: (e: E) -> Unit,
    snapshotHandler: (s: S) -> Unit,
    connecting: (e: Boolean) -> Unit,
    error: (Throwable) -> Unit,
) = launch {
    main@ while (true) {
        // connecting
        var tries = 0
        println("$sessionService joining...")
        connecting(true)
        connect@ while (true) {
            val snapshot = try {
                sessionService.connect()
            } catch (e: RetryableSessionException) {
                println("error joining session: ${e.cause}")
                delay(1.35.pow(max(tries, 3)).seconds)
                tries++
                continue
            } catch (e: Exception) {
                error(e)
                break@main
            }
            snapshotHandler(snapshot)
            println("$sessionService joined")
            connecting(false)
            break
        }

        // listening
        try {
            sessionService.events.collect(eventReducer)
        } catch (e: RetryableSessionException) {
            println("reconnecting on ${e.cause} after 200 ms...")
            delay(200)
            continue
        } catch (e: Exception) {
            error(e)
            break
        }
        println("$sessionService listener loop finished")
        break
    }
}
