package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.poker.game.projection.CreateTournamentCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateTournamentSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RegisterPlayerCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RegisterBotCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.StartGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PauseGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.ResumeGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.DealHandCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RebuyCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.ChangePlayerSeatCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RevealPlayerCardsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.CreateRingGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.BuyInPlayerCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.BuyInBotCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.TerminateRingCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.TopUpCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateRingGameSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.SitInPlayerCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.SitOutPlayerCommand

// converters for CreateTournamentCommand
fun CreateTournamentCommand.toProtocol() = protocol.poker.CreateTournamentCommand(
	hostPlayerId = hostPlayerId,
	tournamentSettings = tournamentSettings.toProtocol(),
	pokerSettings = pokerSettings.toProtocol(),
)
fun protocol.poker.CreateTournamentCommand.toDomain() = CreateTournamentCommand(
	hostPlayerId = hostPlayerId,
	tournamentSettings = tournamentSettings.toDomain(),
	pokerSettings = pokerSettings.toDomain(),
)

// converters for UpdateTournamentSettingsCommand
fun UpdateTournamentSettingsCommand.toProtocol() = protocol.poker.UpdateTournamentSettingsCommand(
	tournamentSettings = tournamentSettings.toProtocol(),
	pokerSettings = pokerSettings.toProtocol(),
)
fun protocol.poker.UpdateTournamentSettingsCommand.toDomain() = UpdateTournamentSettingsCommand(
	tournamentSettings = tournamentSettings.toDomain(),
	pokerSettings = pokerSettings.toDomain(),
)

// converters for RegisterPlayerCommand
fun RegisterPlayerCommand.toProtocol() = protocol.poker.RegisterPlayerCommand(
	playerId = playerId,
	sessionId = sessionId,
)
fun protocol.poker.RegisterPlayerCommand.toDomain() = RegisterPlayerCommand(
	playerId = playerId,
	sessionId = sessionId,
)

// converters for RegisterBotCommand
fun RegisterBotCommand.toProtocol() = protocol.poker.RegisterBotCommand(
	botId = botId,
	sessionId = sessionId,
)
fun protocol.poker.RegisterBotCommand.toDomain() = RegisterBotCommand(
	botId = botId,
	sessionId = sessionId,
)

// converters for StartGameCommand
fun StartGameCommand.toProtocol() = protocol.poker.StartGameCommand
fun protocol.poker.StartGameCommand.toDomain() = StartGameCommand

// converters for PauseGameCommand
fun PauseGameCommand.toProtocol() = protocol.poker.PauseGameCommand
fun protocol.poker.PauseGameCommand.toDomain() = PauseGameCommand

// converters for ResumeGameCommand
fun ResumeGameCommand.toProtocol() = protocol.poker.ResumeGameCommand
fun protocol.poker.ResumeGameCommand.toDomain() = ResumeGameCommand

// converters for DealHandCommand
fun DealHandCommand.toProtocol() = protocol.poker.DealHandCommand(
	tableId = tableId,
)
fun protocol.poker.DealHandCommand.toDomain() = DealHandCommand(
	tableId = tableId,
)

// converters for PlayCommand
fun PlayCommand.toProtocol() = protocol.poker.PlayCommand(
	tableId = tableId,
	requestId = requestId,
	action = action.toProtocol(),
)
fun protocol.poker.PlayCommand.toDomain() = PlayCommand(
	tableId = tableId,
	requestId = requestId,
	action = action.toDomain(),
)

// converters for RebuyCommand
fun RebuyCommand.toProtocol() = protocol.poker.RebuyCommand(
	playerId = playerId,
)
fun protocol.poker.RebuyCommand.toDomain() = RebuyCommand(
	playerId = playerId,
)

// converters for ChangePlayerSeatCommand
fun ChangePlayerSeatCommand.toProtocol() = protocol.poker.ChangePlayerSeatCommand(
	playerId = playerId,
	seat = seat,
)
fun protocol.poker.ChangePlayerSeatCommand.toDomain() = ChangePlayerSeatCommand(
	playerId = playerId,
	seat = seat,
)

// converters for RevealPlayerCardsCommand
fun RevealPlayerCardsCommand.toProtocol() = protocol.poker.RevealPlayerCardsCommand(
	tableId = tableId,
	seat = seat,
	cardIndexes = cardIndexes,
)
fun protocol.poker.RevealPlayerCardsCommand.toDomain() = RevealPlayerCardsCommand(
	tableId = tableId,
	seat = seat,
	cardIndexes = cardIndexes,
)

// converters for CreateRingGameCommand
fun CreateRingGameCommand.toProtocol() = protocol.poker.CreateRingGameCommand(
	hostPlayerId = hostPlayerId,
	ringGameSettings = ringGameSettings.toProtocol(),
	pokerSettings = pokerSettings.toProtocol(),
)
fun protocol.poker.CreateRingGameCommand.toDomain() = CreateRingGameCommand(
	hostPlayerId = hostPlayerId,
	ringGameSettings = ringGameSettings.toDomain(),
	pokerSettings = pokerSettings.toDomain(),
)

// converters for BuyInPlayerCommand
fun BuyInPlayerCommand.toProtocol() = protocol.poker.BuyInPlayerCommand(
	playerId = playerId,
	sessionId = sessionId,
)
fun protocol.poker.BuyInPlayerCommand.toDomain() = BuyInPlayerCommand(
	playerId = playerId,
	sessionId = sessionId,
)

// converters for BuyInBotCommand
fun BuyInBotCommand.toProtocol() = protocol.poker.BuyInBotCommand(
	botId = botId,
	sessionId = sessionId,
)
fun protocol.poker.BuyInBotCommand.toDomain() = BuyInBotCommand(
	botId = botId,
	sessionId = sessionId,
)

// converters for TerminateRingCommand
fun TerminateRingCommand.toProtocol() = protocol.poker.TerminateRingCommand
fun protocol.poker.TerminateRingCommand.toDomain() = TerminateRingCommand

// converters for TopUpCommand
fun TopUpCommand.toProtocol() = protocol.poker.TopUpCommand(
	playerId = playerId,
	amount = amount.toProtocol(),
)
fun protocol.poker.TopUpCommand.toDomain() = TopUpCommand(
	playerId = playerId,
	amount = amount.toDomain(),
)

// converters for UpdateRingGameSettingsCommand
fun UpdateRingGameSettingsCommand.toProtocol() = protocol.poker.UpdateRingGameSettingsCommand(
	ringGameSettings = ringGameSettings.toProtocol(),
	pokerSettings = pokerSettings.toProtocol(),
)
fun protocol.poker.UpdateRingGameSettingsCommand.toDomain() = UpdateRingGameSettingsCommand(
	ringGameSettings = ringGameSettings.toDomain(),
	pokerSettings = pokerSettings.toDomain(),
)

// converters for SitInPlayerCommand
fun SitInPlayerCommand.toProtocol() = protocol.poker.SitInPlayerCommand(
	tableId = tableId,
	playerId = playerId,
	seat = seat,
)
fun protocol.poker.SitInPlayerCommand.toDomain() = SitInPlayerCommand(
	tableId = tableId,
	playerId = playerId,
	seat = seat,
)

// converters for SitOutPlayerCommand
fun SitOutPlayerCommand.toProtocol() = protocol.poker.SitOutPlayerCommand(
	tableId = tableId,
	playerId = playerId,
	seat = seat,
)
fun protocol.poker.SitOutPlayerCommand.toDomain() = SitOutPlayerCommand(
	tableId = tableId,
	playerId = playerId,
	seat = seat,
)

