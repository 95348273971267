package studio.lostjoker.smartdealer.ui.poker.host_game

import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode
import studio.lostjoker.smartdealer.ui.poker.common.helpers.payoutStructure
import studio.lostjoker.smartdealer.ui.poker.common.helpers.sitAndGoBlindStructure
import studio.lostjoker.smartdealer.ui.poker.enum.ActionTimeout
import studio.lostjoker.smartdealer.ui.poker.enum.BlindSpeed
import studio.lostjoker.smartdealer.ui.poker.enum.BlindLevelUp
import studio.lostjoker.smartdealer.ui.poker.enum.RingGameStake
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.poker.enum.GameFormat
import studio.lostjoker.smartdealer.ui.poker.enum.GameVariant
import kotlin.uuid.Uuid

data class RingGameBuyInRange(
    val minBuyIn: Double,
    val maxBuyIn: Double,
)

data class RingGameBlindSettings(
    val ante: Double? = null,
    val smallBlind: Double,
    val bigBlind: Double,
)

data class SitAndGoBlindSettings(
    val minutes: Int? = null,
    val hands: Int? = null,
    val ante: Double? = null,
    val smallBlind: Double,
    val bigBlind: Double,
)

enum class PayoutType {
    Conservative,
    Moderate,
    Aggressive,
}

enum class PayoutRange(val range: ClosedRange<Int>) {
    Range1(2..3),
    Range2(4..6),
    Range3(7..10),
}

data class PayoutSettings(
    val type: PayoutType,
    val structure: Map<PayoutRange, Map<Int, Double>>,
)

data class LateRegistrationConfig(
    val numberOfLevels: Int = 0,
)

data class RebuyConfig(
    val checked: Boolean = false,
    val numberOfRebuys: Int = 1,
    val numberOfLevels: Int = 6, // FIXME This parameter isn't being used at the moment - It is using the late registration duration
)

data class HostGameState(
    val ringGameSettings: RingGameSettings = RingGameSettings(),
    val sitAndGoSettings: SitAndGoSettings = SitAndGoSettings(),
    val tableSize: Int = 10,
    val actionTimeout: ActionTimeout = ActionTimeout.MinuteThirty,
    val gameCode: String? = null,
    val gameId: Uuid? = null,
    val device: Device = Device.Player,
    val gameVariant: GameVariant = GameVariant.NLH,
    val gameFormat: GameFormat = GameFormat.SitAndGo,
    val autoDealHands: Boolean = false,
    val errorMessage: String? = null,
    val errorCode: GameManagementApiErrorCode? = null,
)

data class RingGameSettings(
    val blindSettings: RingGameBlindSettings = RingGameBlindSettings(
        smallBlind = 0.01,
        bigBlind = 0.02,
    ),
    val buyInRange: RingGameBuyInRange = RingGameBuyInRange(
        minBuyIn = 0.4,
        maxBuyIn = 2.0,
    ),
    val blindSettingsSliderPosition: Float = 0f,
    val buyInRangeSliderPosition: ClosedFloatingPointRange<Float> = 2f..10f,
    val gameLength: Int = 12,
    val stake: RingGameStake = RingGameStake.Micro,
)

data class SitAndGoSettings(
    val buyIn: Int = 100,
    val startingStack: Int = 5000,
    val sliderPosition: Float = 0f,
    val blindLevelUp: BlindLevelUp = BlindLevelUp.Hands,
    val blindSpeed: BlindSpeed = BlindSpeed.Turbo,
    val blindSettings: List<SitAndGoBlindSettings> = sitAndGoBlindStructure[BlindSpeed.Turbo] ?: emptyList(),
    val payoutSettings: PayoutSettings = PayoutSettings(PayoutType.Conservative, (payoutStructure[PayoutType.Conservative] ?: emptyMap())),
    val lateRegistration: LateRegistrationConfig = LateRegistrationConfig(),
    val rebuyConfig: RebuyConfig = RebuyConfig(),
)
