package service

import io.ktor.client.HttpClient
import io.ktor.client.plugins.websocket.DefaultClientWebSocketSession
import io.ktor.client.plugins.websocket.WebSockets
import io.ktor.client.plugins.websocket.pingInterval
import io.ktor.client.plugins.websocket.webSocketSession
import io.ktor.serialization.kotlinx.KotlinxWebsocketSerializationConverter
import io.ktor.websocket.close
import kotlinx.serialization.json.Json
import kotlin.time.Duration.Companion.milliseconds

open class AbstractSocketSessionHolder(
    private val socketUrl: String,
) {
    private val json = Json

    private val client = HttpClient{
        install(WebSockets) {
            pingInterval = 2_000.milliseconds
            contentConverter = KotlinxWebsocketSerializationConverter(json)
        }
    }

    protected lateinit var session: DefaultClientWebSocketSession

    protected suspend fun open() {
        session = client.webSocketSession(socketUrl)
    }

    suspend fun close() {
        session.close()
    }

    fun finalize() {
        client.close()
    }
}
