package studio.lostjoker.smartdealer.ui.poker.join_game

import cafe.adriel.voyager.core.model.ScreenModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import service.GameManagementApiService
import service.GameManagementApiServiceException

class JsJoinGameViewModel(apiBaseUrl: String, urlGameCode: String?) : ScreenModel {
    private val _state = MutableStateFlow(JsJoinGameState(urlGameCode = urlGameCode))
    val state = _state.asStateFlow()

    private val gameApi = GameManagementApiService(apiBaseUrl, tokenResolver = { null })

    fun changeGameCode(value: String?) {
        _state.update {
            it.copy(
                urlGameCode = null,
                gameCode = value,
            )
        }
    }

    suspend fun findGameByCode(code: String) {
        if (code.length < 6) return

        try {
            val game = gameApi.findGameByCode(code)
            _state.update {
                it.copy(
                    gameId = game?.gameId,
                )
            }
        } catch (e: GameManagementApiServiceException) {
            _state.update {
                it.copy(
                    errorCode = e.errorCode,
                )
            }
        } catch (e: Exception) {
            _state.update {
                it.copy(
                    errorMessage = e.message,
                )
            }
        }
    }
}
