package studio.lostjoker.smartdealer.domain.poker.game.projection

import kotlinx.datetime.Instant
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.Player
import studio.lostjoker.smartdealer.domain.poker.HighHandRank
import studio.lostjoker.smartdealer.domain.poker.LowHandRank
import studio.lostjoker.smartdealer.domain.poker.RankedHand
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelHandCountRange
import studio.lostjoker.smartdealer.domain.poker.model.BlindType
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAvailableAction
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.Positions
import studio.lostjoker.smartdealer.domain.poker.model.Pot
import studio.lostjoker.smartdealer.domain.poker.model.PotSharePlayerProjection
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.SeatHandResult
import studio.lostjoker.smartdealer.domain.poker.model.SeatRoundResult
import studio.lostjoker.smartdealer.domain.poker.model.SeatSnapshot
import studio.lostjoker.smartdealer.domain.poker.model.SittingOutReason
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import kotlin.time.Duration
import kotlin.uuid.Uuid


sealed interface PokerClientEvent

data class GameStartedEvent(
    val instant: Instant,
) : PokerClientEvent

data class GamePausedEvent(
    val instant: Instant,
) : PokerClientEvent

data class GameResumedEvent(
    val instant: Instant,
) : PokerClientEvent

data class GameEndedEvent(
    val instant: Instant,
) : PokerClientEvent

data class TournamentCreatedEvent(
    val hostPlayerId: String,
    val format: TournamentSettings,
    val settings: PokerSettings,
) : PokerClientEvent

data class TournamentSettingsUpdatedEvent(
    val format: TournamentSettings,
    val settings: PokerSettings,
    val rebuyRemainingCount: Map<String, Int>, // map's key is player id
) : PokerClientEvent

data class PlayerRegisteredEvent(
    val player: Player,
    val sessionId: Uuid,
) : PokerClientEvent

data class BlindLevelChangedEvent(
    val blindLevel: BlindLevel,
    val handCountRange: BlindLevelHandCountRange,
    val lateRegistrationLevelsLeft: Int, // this includes the level that has just started
    val rebuyLevelsLeft: Int, // this includes the level that has just started
) : PokerClientEvent

data class PlayerEliminatedEvent(
    val playerId: String,
    val sessionId: Uuid,
    val rank: Int,
    val prize: Fixed2,
    val rebuy: Int,
    val finalStack: Fixed2,
) : PokerClientEvent

data class PlayerRebuyCompletedEvent(
    val playerId: String,
    val amount: Fixed2,
    val rebuyRemainingCount: Int,
) : PokerClientEvent

data class TableCreatedEvent(
    val tableId: Uuid,
    val settings: PokerSettings,
    val straddle: Boolean,
) : PokerClientEvent

data class TableSettingsUpdatedEvent(
    val tableId: Uuid,
    val settings: PokerSettings,
) : PokerClientEvent

data class PlayerSeatedEvent(
    val tableId: Uuid,
    val player: Player,
    val seat: Int,
    val stack: Fixed2,
) : PokerClientEvent

data class PlayerSatOutEvent(
    val tableId: Uuid,
    val seat: Int,
    val reason: SittingOutReason,
) : PokerClientEvent

data class PlayerSatInEvent(
    val tableId: Uuid,
    val seat: Int,
) : PokerClientEvent

data class PlayerStackUpdatedEvent(
    val tableId: Uuid,
    val playerId: String,
    val seat: Int,
    val amount: Fixed2,
    val stack: Fixed2,
) : PokerClientEvent

data class PlayerBlindMissedEvent(
    val tableId: Uuid,
    val seat: Int,
    val blindType: BlindType,
) : PokerClientEvent

data class HandStartedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val handIndex: Int, // zero-based
    val positions: Positions,
    val settings: PokerSettings,
) : PokerClientEvent

data class RoundStartedEvent(
    val roundIndex: Int,
    val potSizeBeforeRound: Fixed2,
    val remainingStacks: List<SeatSnapshot>,
) : PokerClientEvent

data class ActionRequestedEvent(
    val requestId: Uuid,
    val seat: Int,
    val availableActions: Set<PlayerHandAvailableAction>,
    val instant: Instant,
    val timeout: Duration,
) : PokerClientEvent

data class ActionPerformedEvent(
    val seat: Int,
    val action: PlayerHandAction,
    val requestId: Uuid?,
    val bettingStack: Fixed2,
    val remainingStack: Fixed2,
    val totalPotSize: Fixed2,
    val auto: Boolean,
    val away: Boolean,
) : PokerClientEvent

data object BlindsPostedEvent : PokerClientEvent

data class PlayerCardsDealtEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seat: Int,
    val cards: List<DealtRankedCardPlayerView>,
) : PokerClientEvent

data class PlayerCardsRevealedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seat: Int,
    val cards: List<DealtRankedCardPlayerView>,
) : PokerClientEvent

data class CommunityCardsDealtEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val cards: List<DealtRankedCardPlayerView>,
    val highHandRanks: Map<Int, RankedHand<HighHandRank>>,
    val lowHandRanks: Map<Int, RankedHand<LowHandRank>>,
) : PokerClientEvent

data class RoundEndedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val roundIndex: Int,
    val seatResults: List<SeatRoundResult>,
    val potContributions: List<Pot>,
) : PokerClientEvent

data class HandEndedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seatResults: List<SeatHandResult>,
    val potShares: List<PotSharePlayerProjection>,
    val pots: List<Pot>,
) : PokerClientEvent

data class RingCreatedEvent(
    val hostPlayerId: String,
    val format: RingGameSettings,
    val settings: PokerSettings,
) : PokerClientEvent

data class PlayerBoughtInEvent(
    val player: Player,
    val sessionId: Uuid,
) : PokerClientEvent

data class PlayerToppedUpEvent(
    val playerId: String,
    val amount: Fixed2,
) : PokerClientEvent

data class RingGameSettingsUpdatedEvent(
    val format: RingGameSettings,
    val settings: PokerSettings,
) : PokerClientEvent
