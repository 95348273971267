
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.Surface
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import cafe.adriel.voyager.navigator.CurrentScreen
import cafe.adriel.voyager.navigator.Navigator
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.initialize
import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams
import studio.lostjoker.smartdealer.Offering
import studio.lostjoker.smartdealer.helpers.LocalSnackbar
import studio.lostjoker.smartdealer.helpers.calculateWebWindowSizeClass
import studio.lostjoker.smartdealer.helpers.isDarkTheme
import studio.lostjoker.smartdealer.helpers.rememberSnackBarDelegate
import studio.lostjoker.smartdealer.notification.LocalNotificationService
import studio.lostjoker.smartdealer.notification.rememberNotificationService
import studio.lostjoker.smartdealer.ui.poker.join_game.JsJoinGameScreen
import studio.lostjoker.smartdealer.ui.theme.LocalDarkTheme
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun MainView(
    offering: Offering,
    gameplayBaseUrl: String,
    apiBaseUrl: String,
) {
    val snackbarHostState = SnackbarHostState()
    val snackbarDelegate = rememberSnackBarDelegate(snackbarHostState)
    val notificationService = rememberNotificationService()
    val isDarkTheme = remember { mutableStateOf(isDarkTheme()) }

    val queryString = window.location.search
    val urlParams = URLSearchParams(queryString)
    val gameCode = urlParams.get("gameCode")

    val windowSizeClass = calculateWebWindowSizeClass()

    Firebase.initialize(
        options = FirebaseOptions(
            apiKey = "AIzaSyDG99Tj9wpU7K_Wcwp77TpyMXoHQo7VV0s",
            authDomain = "lj-smart-dealer.firebaseapp.com",
            projectId = "lj-smart-dealer",
            storageBucket = "lj-smart-dealer.firebasestorage.app",
            gcmSenderId = "70993189516",
            applicationId = "1:70993189516:web:9e5480296c63a2fde979a9",
        ),
    )

    CompositionLocalProvider(
        LocalSnackbar provides snackbarDelegate,
        LocalNotificationService provides notificationService,
        LocalDarkTheme provides isDarkTheme,
    ) {
        PokerAppTheme(windowSizeClass.first, windowSizeClass.second, LocalDarkTheme.current.value) {
            Navigator(
                screen = JsJoinGameScreen(gameplayBaseUrl, apiBaseUrl, gameCode),
            ) {
                Surface(modifier = Modifier.fillMaxSize()) {
                    CurrentScreen()
                }
            }
        }
    }
}
