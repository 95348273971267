package studio.lostjoker.smartdealer.ui.poker.join_game

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.model.rememberScreenModel
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.core.screen.ScreenKey
import cafe.adriel.voyager.core.screen.uniqueScreenKey
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.join_game_game_code
import smartdealer.appshared.generated.resources.logo_long_text_horizontal_black
import smartdealer.appshared.generated.resources.logo_long_text_horizontal_white
import smartdealer.appshared.generated.resources.poker_watch_live
import studio.lostjoker.smartdealer.firebase.FirebaseAnalytics
import studio.lostjoker.smartdealer.firebase.FirebaseParamsBuilder
import studio.lostjoker.smartdealer.firebase.logEvent
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode
import studio.lostjoker.smartdealer.helpers.getGameManagementApiErrorMessage
import studio.lostjoker.smartdealer.ui.components.GameCodeInput
import studio.lostjoker.smartdealer.ui.poker.devices.table.JsTableDeviceScreen
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import studio.lostjoker.smartdealer.ui.theme.darkColorScheme
import kotlin.uuid.Uuid

class JsJoinGameScreen(
    private val gameplayBaseUrl: String,
    private val apiBaseUrl: String,
    private val urlGameCode: String? = null,
) : Screen {
    override val key: ScreenKey = uniqueScreenKey

    @Composable
    override fun Content() {
        val analytics = Firebase.analytics
        val navigator = LocalNavigator.currentOrThrow
        val viewModel = rememberScreenModel(key) { JsJoinGameViewModel(apiBaseUrl, urlGameCode) }
        val state by viewModel.state.collectAsState()

        LaunchedEffect(state.urlGameCode, state.gameId) {
            state.urlGameCode?.let { gameCode ->
                state.gameId?.let { gameId ->
                    analytics.logEvent(
                        FirebaseAnalytics.Event.JOIN_GAME,
                        listOf(
                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.PLAYER_ID, "watcher"),
                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode!!),
                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                        ),
                    )
                    navigator.push(
                        JsTableDeviceScreen(
                            gameCode = gameCode,
                            gameId = gameId,
                            gameplayBaseUrl = gameplayBaseUrl,
                            apiBaseUrl = apiBaseUrl,
                        ),
                    )
                } ?: viewModel.findGameByCode(gameCode)
            }
        }

        LaunchedEffect(state.gameCode) {
            state.gameCode?.let {
                viewModel.findGameByCode(it)
            }
        }

        JsJoinGameScreenView(
            gameCode = state.gameCode,
            errorMessage = state.errorMessage,
            errorCode = state.errorCode,
            gameId = state.gameId,
            onCodeChange = viewModel::changeGameCode,
            loadTable = {
                state.gameId?.let { gameId ->
                    navigator.push(
                        JsTableDeviceScreen(
                            gameCode = state.gameCode!!,
                            gameId = gameId,
                            gameplayBaseUrl = gameplayBaseUrl,
                            apiBaseUrl = apiBaseUrl,
                        ),
                    )
                }
            },
        )
    }
}

@Composable
fun JsJoinGameScreenView(
    gameCode: String?,
    errorMessage: String?,
    onCodeChange: (newCode: String) -> Unit,
    loadTable: () -> Unit,
    gameId: Uuid?,
    errorCode: GameManagementApiErrorCode?,
) {
    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        Column(
            modifier = Modifier.width(IntrinsicSize.Max),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
        ) {
            Image(
                painter = if (PokerAppTheme.colors == darkColorScheme) {
                    painterResource(Res.drawable.logo_long_text_horizontal_white)
                } else {
                    painterResource(Res.drawable.logo_long_text_horizontal_black)
                },
                contentDescription = null,
                contentScale = ContentScale.Fit,
            )

            Text(
                text = stringResource(Res.string.join_game_game_code),
                style = PokerAppTheme.typography.titleMedium,
            )

            Spacer(Modifier.height(16.dp))

            GameCodeInput(gameCode ?: "") {
                onCodeChange(it ?: "")
            }

            Spacer(Modifier.height(16.dp))

            Button(
                modifier = Modifier.fillMaxWidth(.8f),
                enabled = gameId != null,
                onClick = loadTable,
            ) {
                Text(
                    text = stringResource(Res.string.poker_watch_live),
                    style = PokerAppTheme.typography.titleMedium,
                )
            }

            Spacer(Modifier.height(8.dp))

            errorMessage?.let {
                Text(
                    text = it,
                    style = PokerAppTheme.typography.labelLarge,
                )
            }

            errorCode?.let {
                Text(
                    text = getGameManagementApiErrorMessage(it),
                    style = PokerAppTheme.typography.labelLarge,
                )
            }
        }
    }
}
