package studio.lostjoker.smartdealer.ui.poker.common.helpers

import studio.lostjoker.smartdealer.ui.poker.enum.BlindLevelUp
import studio.lostjoker.smartdealer.ui.poker.enum.BlindSpeed
import studio.lostjoker.smartdealer.ui.poker.enum.RingGameStake
import studio.lostjoker.smartdealer.ui.poker.host_game.RingGameBlindSettings
import studio.lostjoker.smartdealer.ui.poker.host_game.SitAndGoBlindSettings

val ringGameBlindStructure: Map<RingGameStake, List<RingGameBlindSettings>> = mapOf(
    RingGameStake.Micro to listOf(
        RingGameBlindSettings(null, 0.01, 0.02),
        RingGameBlindSettings(null, 0.02, 0.04),
        RingGameBlindSettings(null, 0.05, 0.10),
        RingGameBlindSettings(null, 0.10, 0.20),
        RingGameBlindSettings(null, 0.25, 0.50),
        RingGameBlindSettings(null, 0.30, 0.60),
        RingGameBlindSettings(null, 0.50, 1.00),
        RingGameBlindSettings(null, 0.80, 1.60),
    ),
    RingGameStake.Low to listOf(
        RingGameBlindSettings(null, 1.0, 2.0),
        RingGameBlindSettings(null, 2.0, 4.0),
        RingGameBlindSettings(null, 2.5, 5.0),
        RingGameBlindSettings(null, 3.0, 6.0),
        RingGameBlindSettings(null, 5.0, 10.0),
        RingGameBlindSettings(null, 7.0, 14.0),
        RingGameBlindSettings(null, 8.0, 16.0),
        RingGameBlindSettings(null, 9.0, 18.0),
    ),
    RingGameStake.Medium to listOf(
        RingGameBlindSettings(null, 10.0, 20.0),
        RingGameBlindSettings(null, 15.0, 30.0),
        RingGameBlindSettings(null, 25.0, 50.0),
        RingGameBlindSettings(null, 30.0, 60.0),
        RingGameBlindSettings(null, 50.0, 100.0),
        RingGameBlindSettings(null, 75.0, 150.0),
    ),
    RingGameStake.High to listOf(
        RingGameBlindSettings(null, 100.0, 200.0),
        RingGameBlindSettings(null, 125.0, 250.0),
        RingGameBlindSettings(null, 150.0, 300.0),
        RingGameBlindSettings(null, 200.0, 400.0),
        RingGameBlindSettings(null, 300.0, 600.0),
        RingGameBlindSettings(null, 500.0, 1000.0),
        RingGameBlindSettings(null, 600.0, 1200.0),
        RingGameBlindSettings(null, 700.0, 1400.0),
        RingGameBlindSettings(null, 800.0, 1600.0),
        RingGameBlindSettings(null, 1000.0, 2000.0),
    ),
)

val sitAndGoBlindStructure: Map<BlindSpeed, List<SitAndGoBlindSettings>> = mapOf(
    BlindSpeed.Hyper to listOf(
        SitAndGoBlindSettings(null, 25.0, 50.0),
        SitAndGoBlindSettings(null, 50.0, 100.0),
        SitAndGoBlindSettings(null, 75.0, 150.0),
        SitAndGoBlindSettings(null, 100.0, 200.0),
        SitAndGoBlindSettings(null, 125.0, 250.0),
        SitAndGoBlindSettings(null, 150.0, 300.0),
        SitAndGoBlindSettings(null, 200.0, 400.0),
        SitAndGoBlindSettings(null, 250.0, 500.0),
        SitAndGoBlindSettings(null, 300.0, 600.0),
        SitAndGoBlindSettings(null, 400.0, 800.0),
        SitAndGoBlindSettings(null, 500.0, 1000.0),
        SitAndGoBlindSettings(null, 750.0, 1500.0),
        SitAndGoBlindSettings(null, 1000.0, 2000.0),
        SitAndGoBlindSettings(null, 1500.0, 3000.0),
        SitAndGoBlindSettings(null, 2000.0, 4000.0),
        SitAndGoBlindSettings(null, 2500.0, 5000.0),
        SitAndGoBlindSettings(null, 3000.0, 6000.0),
        SitAndGoBlindSettings(null, 4000.0, 8000.0),
        SitAndGoBlindSettings(null, 5000.0, 10000.0),
        SitAndGoBlindSettings(null, 7500.0, 15000.0),
        SitAndGoBlindSettings(null, 10000.0, 20000.0),
        SitAndGoBlindSettings(null, 12500.0, 25000.0),
        SitAndGoBlindSettings(null, 15000.0, 30000.0),
        SitAndGoBlindSettings(null, 20000.0, 40000.0),
        SitAndGoBlindSettings(null, 25000.0, 50000.0),
        SitAndGoBlindSettings(null, 30000.0, 60000.0),
        SitAndGoBlindSettings(null, 40000.0, 80000.0),
        SitAndGoBlindSettings(null, 50000.0, 100000.0),
        SitAndGoBlindSettings(null, 60000.0, 120000.0),
        SitAndGoBlindSettings(null, 80000.0, 160000.0),
        SitAndGoBlindSettings(null, 100000.0, 200000.0),
        SitAndGoBlindSettings(null, 125000.0, 250000.0),
        SitAndGoBlindSettings(null, 150000.0, 300000.0),
        SitAndGoBlindSettings(null, 175000.0, 350000.0),
        SitAndGoBlindSettings(null, 200000.0, 400000.0),
        SitAndGoBlindSettings(null, 250000.0, 500000.0),
        SitAndGoBlindSettings(null, 300000.0, 600000.0),
        SitAndGoBlindSettings(null, 400000.0, 800000.0),
        SitAndGoBlindSettings(null, 500000.0, 1000000.0),
        SitAndGoBlindSettings(null, 600000.0, 1200000.0),
    ),
    BlindSpeed.Turbo to listOf(
        SitAndGoBlindSettings(null, 50.0, 100.0),
        SitAndGoBlindSettings(null, 60.0, 120.0),
        SitAndGoBlindSettings(null, 80.0, 160.0),
        SitAndGoBlindSettings(null, 100.0, 200.0),
        SitAndGoBlindSettings(null, 125.0, 250.0),
        SitAndGoBlindSettings(null, 150.0, 300.0),
        SitAndGoBlindSettings(null, 200.0, 400.0),
        SitAndGoBlindSettings(null, 250.0, 500.0),
        SitAndGoBlindSettings(null, 300.0, 600.0),
        SitAndGoBlindSettings(null, 400.0, 800.0),
        SitAndGoBlindSettings(null, 500.0, 1000.0),
        SitAndGoBlindSettings(null, 600.0, 1200.0),
        SitAndGoBlindSettings(null, 800.0, 1600.0),
        SitAndGoBlindSettings(null, 1000.0, 2000.0),
        SitAndGoBlindSettings(null, 1250.0, 2500.0),
        SitAndGoBlindSettings(null, 1500.0, 3000.0),
        SitAndGoBlindSettings(null, 2000.0, 4000.0),
        SitAndGoBlindSettings(null, 2500.0, 5000.0),
        SitAndGoBlindSettings(null, 3000.0, 6000.0),
        SitAndGoBlindSettings(null, 4000.0, 8000.0),
        SitAndGoBlindSettings(null, 5000.0, 10000.0),
        SitAndGoBlindSettings(null, 6000.0, 12000.0),
        SitAndGoBlindSettings(null, 8000.0, 16000.0),
        SitAndGoBlindSettings(null, 10000.0, 20000.0),
        SitAndGoBlindSettings(null, 12500.0, 25000.0),
        SitAndGoBlindSettings(null, 15000.0, 30000.0),
        SitAndGoBlindSettings(null, 20000.0, 40000.0),
        SitAndGoBlindSettings(null, 25000.0, 50000.0),
        SitAndGoBlindSettings(null, 30000.0, 60000.0),
        SitAndGoBlindSettings(null, 40000.0, 80000.0),
        SitAndGoBlindSettings(null, 50000.0, 100000.0),
        SitAndGoBlindSettings(null, 60000.0, 120000.0),
        SitAndGoBlindSettings(null, 80000.0, 160000.0),
        SitAndGoBlindSettings(null, 100000.0, 200000.0),
        SitAndGoBlindSettings(null, 125000.0, 250000.0),
        SitAndGoBlindSettings(null, 150000.0, 300000.0),
        SitAndGoBlindSettings(null, 200000.0, 400000.0),
        SitAndGoBlindSettings(null, 250000.0, 500000.0),
        SitAndGoBlindSettings(null, 300000.0, 600000.0),
        SitAndGoBlindSettings(null, 400000.0, 800000.0),
    ),
    BlindSpeed.Regular to listOf(
        SitAndGoBlindSettings(null, 50.0, 100.0),
        SitAndGoBlindSettings(null, 60.0, 120.0),
        SitAndGoBlindSettings(null, 80.0, 160.0),
        SitAndGoBlindSettings(null, 90.0, 180.0),
        SitAndGoBlindSettings(null, 100.0, 200.0),
        SitAndGoBlindSettings(null, 125.0, 250.0),
        SitAndGoBlindSettings(null, 150.0, 300.0),
        SitAndGoBlindSettings(null, 200.0, 400.0),
        SitAndGoBlindSettings(null, 250.0, 500.0),
        SitAndGoBlindSettings(null, 300.0, 600.0),
        SitAndGoBlindSettings(null, 350.0, 700.0),
        SitAndGoBlindSettings(null, 400.0, 800.0),
        SitAndGoBlindSettings(null, 500.0, 1000.0),
        SitAndGoBlindSettings(null, 600.0, 1200.0),
        SitAndGoBlindSettings(null, 800.0, 1600.0),
        SitAndGoBlindSettings(null, 1000.0, 2000.0),
        SitAndGoBlindSettings(null, 1200.0, 2400.0),
        SitAndGoBlindSettings(null, 1400.0, 2800.0),
        SitAndGoBlindSettings(null, 1500.0, 3000.0),
        SitAndGoBlindSettings(null, 1600.0, 3200.0),
        SitAndGoBlindSettings(null, 1800.0, 3600.0),
        SitAndGoBlindSettings(null, 2000.0, 4000.0),
        SitAndGoBlindSettings(null, 2500.0, 5000.0),
        SitAndGoBlindSettings(null, 3000.0, 6000.0),
        SitAndGoBlindSettings(null, 3500.0, 7000.0),
        SitAndGoBlindSettings(null, 4000.0, 8000.0),
        SitAndGoBlindSettings(null, 4500.0, 9000.0),
        SitAndGoBlindSettings(null, 5000.0, 10000.0),
        SitAndGoBlindSettings(null, 6000.0, 12000.0),
        SitAndGoBlindSettings(null, 8000.0, 16000.0),
        SitAndGoBlindSettings(null, 10000.0, 20000.0),
        SitAndGoBlindSettings(null, 12500.0, 25000.0),
        SitAndGoBlindSettings(null, 15000.0, 30000.0),
        SitAndGoBlindSettings(null, 17500.0, 35000.0),
        SitAndGoBlindSettings(null, 20000.0, 40000.0),
        SitAndGoBlindSettings(null, 25000.0, 50000.0),
        SitAndGoBlindSettings(null, 30000.0, 60000.0),
        SitAndGoBlindSettings(null, 35000.0, 70000.0),
        SitAndGoBlindSettings(null, 40000.0, 80000.0),
        SitAndGoBlindSettings(null, 45000.0, 90000.0),
        SitAndGoBlindSettings(null, 50000.0, 100000.0),
        SitAndGoBlindSettings(null, 60000.0, 120000.0),
        SitAndGoBlindSettings(null, 80000.0, 160000.0),
        SitAndGoBlindSettings(null, 100000.0, 200000.0),
        SitAndGoBlindSettings(null, 125000.0, 250000.0),
        SitAndGoBlindSettings(null, 150000.0, 300000.0),
        SitAndGoBlindSettings(null, 175000.0, 350000.0),
        SitAndGoBlindSettings(null, 200000.0, 400000.0),
        SitAndGoBlindSettings(null, 250000.0, 500000.0),
        SitAndGoBlindSettings(null, 300000.0, 600000.0),
    ),
    BlindSpeed.Slow to listOf(
        SitAndGoBlindSettings(null, 25.0, 50.0),
        SitAndGoBlindSettings(null, 30.0, 60.0),
        SitAndGoBlindSettings(null, 40.0, 80.0),
        SitAndGoBlindSettings(null, 50.0, 100.0),
        SitAndGoBlindSettings(null, 60.0, 120.0),
        SitAndGoBlindSettings(null, 80.0, 160.0),
        SitAndGoBlindSettings(null, 100.0, 200.0),
        SitAndGoBlindSettings(null, 125.0, 250.0),
        SitAndGoBlindSettings(null, 150.0, 300.0),
        SitAndGoBlindSettings(null, 175.0, 350.0),
        SitAndGoBlindSettings(null, 200.0, 400.0),
        SitAndGoBlindSettings(null, 250.0, 500.0),
        SitAndGoBlindSettings(null, 300.0, 600.0),
        SitAndGoBlindSettings(null, 350.0, 700.0),
        SitAndGoBlindSettings(null, 400.0, 800.0),
        SitAndGoBlindSettings(null, 500.0, 1000.0),
        SitAndGoBlindSettings(null, 600.0, 1200.0),
        SitAndGoBlindSettings(null, 800.0, 1600.0),
        SitAndGoBlindSettings(null, 1000.0, 2000.0),
        SitAndGoBlindSettings(null, 1250.0, 2500.0),
        SitAndGoBlindSettings(null, 1500.0, 3000.0),
        SitAndGoBlindSettings(null, 1750.0, 3500.0),
        SitAndGoBlindSettings(null, 2000.0, 4000.0),
        SitAndGoBlindSettings(null, 2500.0, 5000.0),
        SitAndGoBlindSettings(null, 3000.0, 6000.0),
        SitAndGoBlindSettings(null, 3500.0, 7000.0),
        SitAndGoBlindSettings(null, 4000.0, 8000.0),
        SitAndGoBlindSettings(null, 5000.0, 10000.0),
        SitAndGoBlindSettings(null, 6000.0, 12000.0),
        SitAndGoBlindSettings(null, 8000.0, 16000.0),
        SitAndGoBlindSettings(null, 10000.0, 20000.0),
        SitAndGoBlindSettings(null, 12500.0, 25000.0),
        SitAndGoBlindSettings(null, 15000.0, 30000.0),
        SitAndGoBlindSettings(null, 17500.0, 35000.0),
        SitAndGoBlindSettings(null, 20000.0, 40000.0),
        SitAndGoBlindSettings(null, 25000.0, 50000.0),
        SitAndGoBlindSettings(null, 30000.0, 60000.0),
        SitAndGoBlindSettings(null, 35000.0, 70000.0),
        SitAndGoBlindSettings(null, 40000.0, 80000.0),
        SitAndGoBlindSettings(null, 50000.0, 100000.0),
        SitAndGoBlindSettings(null, 60000.0, 120000.0),
        SitAndGoBlindSettings(null, 80000.0, 160000.0),
        SitAndGoBlindSettings(null, 100000.0, 200000.0),
        SitAndGoBlindSettings(null, 125000.0, 250000.0),
        SitAndGoBlindSettings(null, 150000.0, 300000.0),
        SitAndGoBlindSettings(null, 175000.0, 350000.0),
        SitAndGoBlindSettings(null, 200000.0, 400000.0),
        SitAndGoBlindSettings(null, 250000.0, 500000.0),
        SitAndGoBlindSettings(null, 300000.0, 600000.0),
        SitAndGoBlindSettings(null, 350000.0, 700000.0),
    ),
)

val sitAndGoBlindLevelUpDuration: Map<BlindLevelUp, List<Int>> = mapOf(
    BlindLevelUp.Time to listOf(
        3,
        5,
        10,
        15,
    ),
    BlindLevelUp.Hands to listOf(
        3,
        5,
        8,
        10,
    ),
)

internal fun findRingGameNearestBlind(blindOptions: RingGameStake, sliderPosition: Int): RingGameBlindSettings {
    val blindStructure = ringGameBlindStructure[blindOptions] ?: throw Exception("Invalid blind structure")
    return blindStructure[sliderPosition - 1]
}

internal fun findSitAndGoNearestBlindLevelDuration(blindLevelUp: BlindLevelUp, sliderPosition: Int): Int {
    val blindLevelDuration = sitAndGoBlindLevelUpDuration[blindLevelUp] ?: throw Exception("Invalid blind level up duration")
    return blindLevelDuration[sliderPosition - 1]
}

