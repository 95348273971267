package studio.lostjoker.smartdealer.ui.poker.common.helpers

import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.TileMode

val colorButtonContainer = Color.White
val colorButtonContent = Color(0xFF043820)

val tipTheDealerButtonContainer = Color(0xFFFF6600)
val tipTheDealerButtonContent = Color.White

val colorLogo = Color(0xFF0BC753)
val colorStack = Color(0xFFD4B672)
val colorFold = Color(0xFFC04144)
val colorCheck = Color(0xFF01A767)
val colorCall = Color(0xFF01A767)
val colorBet = Color(0xFFEAA106)
val colorRaise = Color(0xFFEAA106)
val colorWin = Color.Blue
val colorMuck = Color(0xFF01A767)
val colorShow = Color(0xFFEAA106)
val colorBlind = Color.DarkGray
val colorHandResult = Color(0xFFFF6600)
val fourColoredDeckDiamonds = Color(0xFF155291)
val fourColoredDeckHearts = Color(0xFFa32121)
val fourColoredDeckSpades = Color(0xFF2b2b2b)
val fourColoredDeckClubs = Color(0xFF1b6d1b)

val brushFlashlightBulb = Brush.radialGradient(
    colors = listOf(
        Color(0xFFFFE135),
        Color(0xFFFFD700),
        Color(0x00FFD700),
    ),
)

val brushRainbow = Brush.linearGradient(
    colors = listOf(
        Color.Red,
        Color(0xFFFFA500),
        Color.Yellow,
        Color.Green,
    ),
)

val tableFeltGreenColor1 = Color(0xFF009221)
val tableFeltGreenColor2 = Color(0xFF006C17)

val tableFeltGreen = Brush.radialGradient(
    colors = listOf(
        tableFeltGreenColor1,
        tableFeltGreenColor2,
    ),
)

val tableFeltBlueColor1 = Color(0xFF135C8F)
val tableFeltBlueColor2 = Color(0xFF05436D)

val tableFeltBlue = Brush.radialGradient(
    colors = listOf(
        tableFeltBlueColor1,
        tableFeltBlueColor2,
    ),
)

val tableFeltRedColor1 = Color(0xFFE31B1D)
val tableFeltRedColor2 = Color(0xFF910504)

val tableFeltRed = Brush.radialGradient(
    colors = listOf(
        tableFeltRedColor1,
        tableFeltRedColor2,
    ),
)
