package studio.lostjoker.smartdealer.preferences

sealed class PreferenceEnums {
    enum class Storage(val key: String) {
        Poker("sd-poker")
    }

    enum class InGame(val key: String) {
        VerticalDisplay("vertical-display"),
        TableLayout("table-layout"),
        TableFelt("table-felt"),
        CardLayout("card-layout"),
        CardStyle("card-style"),
        CardBackStyle("card-back-style"),
        GameplayTutorial("gameplay-tutorial"),
        TerminateRingGameReminder("terminate-ring-game-reminder"),
        CardPeek("card-peek"),
    }

    enum class Settings(val key: String) {
        DarkMode("dark-mode"),
        Language("preferred-language"),
        Sound("sound-enabled")
    }
}


