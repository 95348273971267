package service

import io.ktor.client.utils.*
import io.ktor.websocket.CloseReason
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

abstract class AbstractSessionService<E, C, S>(
    socketUrl: String,
) : AbstractSocketSessionHolder(socketUrl), SessionService<E, C, S> {
    protected abstract suspend fun receiveSnapshot(): S
    protected abstract suspend fun receiveEvent(): E
    protected abstract suspend fun sendCommand(command: C)

    override suspend fun connect(): S {
        try {
            open()
            return receiveSnapshot()
        } catch (e: NoSuchElementException) {
            checkNormalTerminationOrThrow(session.closeReason.await(), e)
            throw IllegalStateException("Snapshot should always be received before normal termination (code 1000)", e)
        } catch (e: Exception) {
            translateException(e)
        }
    }

    override val events: Flow<E> = flow {
        while (true) {
            val event = try {
                receiveEvent()
            } catch (e: NoSuchElementException) {
                checkNormalTerminationOrThrow(session.closeReason.await(), e)
                break
            } catch (e: Exception) {
                translateException(e)
            }
            emit(event)
        }
    }

    override suspend fun send(command: C) {
        try {
            sendCommand(command)
        } catch (e: Exception) {
            translateException(e)
        }
    }

    private fun checkNormalTerminationOrThrow(reason: CloseReason?, e: NoSuchElementException) {
        val shouldRetry = when (reason?.knownReason) {
            CloseReason.Codes.NORMAL -> return
            CloseReason.Codes.GOING_AWAY -> true
            CloseReason.Codes.PROTOCOL_ERROR -> false
            CloseReason.Codes.CANNOT_ACCEPT -> false
            CloseReason.Codes.CLOSED_ABNORMALLY -> true
            CloseReason.Codes.NOT_CONSISTENT -> false
            CloseReason.Codes.VIOLATED_POLICY -> false
            CloseReason.Codes.TOO_BIG -> false
            CloseReason.Codes.NO_EXTENSION -> false
            CloseReason.Codes.INTERNAL_ERROR -> true
            CloseReason.Codes.SERVICE_RESTART -> true
            CloseReason.Codes.TRY_AGAIN_LATER -> true
            null -> when (reason?.code) {
                4003.toShort() -> false
                4004.toShort() -> false
                else -> true // assuming retryable if unknown
            }
        }
        if (shouldRetry) {
            throw RetryableSessionException(e)
        } else {
            throw NonRetryableSessionException(reason?.code, e)
        }
    }

    private fun translateException(e: Exception): Nothing {
        when (val cause = e.unwrapCancellationException()) {
            // I don't think the IO exception should be wrapped in a cancellation exception, we need to investigate this further
            is kotlinx.io.IOException -> throw RetryableSessionException(cause)
            else -> throw e
        }
    }
}
