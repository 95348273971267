@file:AutoConverters(
    [
        AutoConverter(domainClass = CreateTournamentCommand::class, protocolClass = protocol.poker.CreateTournamentCommand::class),
        AutoConverter(domainClass = UpdateTournamentSettingsCommand::class, protocolClass = protocol.poker.UpdateTournamentSettingsCommand::class),
        AutoConverter(domainClass = RegisterPlayerCommand::class, protocolClass = protocol.poker.RegisterPlayerCommand::class),
        AutoConverter(domainClass = StartGameCommand::class, protocolClass = protocol.poker.StartGameCommand::class),
        AutoConverter(domainClass = PauseGameCommand::class, protocolClass = protocol.poker.PauseGameCommand::class),
        AutoConverter(domainClass = ResumeGameCommand::class, protocolClass = protocol.poker.ResumeGameCommand::class),
        AutoConverter(domainClass = DealHandCommand::class, protocolClass = protocol.poker.DealHandCommand::class),
        AutoConverter(domainClass = PlayCommand::class, protocolClass = protocol.poker.PlayCommand::class),
        AutoConverter(domainClass = RebuyCommand::class, protocolClass = protocol.poker.RebuyCommand::class),
        AutoConverter(domainClass = ChangePlayerSeatCommand::class, protocolClass = protocol.poker.ChangePlayerSeatCommand::class),
        AutoConverter(domainClass = RevealPlayerCardsCommand::class, protocolClass = protocol.poker.RevealPlayerCardsCommand::class),
        AutoConverter(domainClass = CreateRingGameCommand::class, protocolClass = protocol.poker.CreateRingGameCommand::class),
        AutoConverter(domainClass = BuyInCommand::class, protocolClass = protocol.poker.BuyInCommand::class),
        AutoConverter(domainClass = TerminateRingCommand::class, protocolClass = protocol.poker.TerminateRingCommand::class),
        AutoConverter(domainClass = TopUpCommand::class, protocolClass = protocol.poker.TopUpCommand::class),
        AutoConverter(domainClass = UpdateRingGameSettingsCommand::class, protocolClass = protocol.poker.UpdateRingGameSettingsCommand::class),
        AutoConverter(domainClass = SitInPlayerCommand::class, protocolClass = protocol.poker.SitInPlayerCommand::class),
        AutoConverter(domainClass = SitOutPlayerCommand::class, protocolClass = protocol.poker.SitOutPlayerCommand::class),
    ],
)

package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.poker.game.projection.BuyInCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.ChangePlayerSeatCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.CreateRingGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.CreateTournamentCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.DealHandCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PauseGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerPlayerCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerTableCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RebuyCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RegisterPlayerCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.ResumeGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.RevealPlayerCardsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.StartGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.TerminateRingCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.TopUpCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateRingGameSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateTournamentSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.SitInPlayerCommand
import studio.lostjoker.smartdealer.contract.annotation.AutoConverter
import studio.lostjoker.smartdealer.contract.annotation.AutoConverters
import studio.lostjoker.smartdealer.domain.poker.game.projection.SitOutPlayerCommand

fun PokerPlayerCommand.toProtocol(): protocol.poker.PokerPlayerCommand = when (this) {
    is CreateTournamentCommand -> toProtocol()
    is UpdateTournamentSettingsCommand -> toProtocol()
    is RegisterPlayerCommand -> toProtocol()
    is StartGameCommand -> toProtocol()
    is PauseGameCommand -> toProtocol()
    is ResumeGameCommand -> toProtocol()
    is DealHandCommand -> toProtocol()
    is PlayCommand -> toProtocol()
    is RebuyCommand -> toProtocol()
    is ChangePlayerSeatCommand -> toProtocol()
    is RevealPlayerCardsCommand -> toProtocol()
    is CreateRingGameCommand -> toProtocol()
    is BuyInCommand -> toProtocol()
    is TerminateRingCommand -> toProtocol()
    is TopUpCommand -> toProtocol()
    is UpdateRingGameSettingsCommand -> toProtocol()
    is SitInPlayerCommand -> toProtocol()
    is SitOutPlayerCommand -> toProtocol()
}

fun PokerTableCommand.toProtocol(): protocol.poker.PokerTableCommand = when (this) {
    is CreateTournamentCommand -> toProtocol()
    is UpdateTournamentSettingsCommand -> toProtocol()
    is StartGameCommand -> toProtocol()
    is PauseGameCommand -> toProtocol()
    is ResumeGameCommand -> toProtocol()
    is DealHandCommand -> toProtocol()
    is CreateRingGameCommand -> toProtocol()
    is TerminateRingCommand -> toProtocol()
    is UpdateRingGameSettingsCommand -> toProtocol()
}

fun protocol.poker.PokerPlayerCommand.toDomain(): PokerPlayerCommand = when (this) {
    is protocol.poker.CreateTournamentCommand -> toDomain()
    is protocol.poker.UpdateTournamentSettingsCommand -> toDomain()
    is protocol.poker.RegisterPlayerCommand -> toDomain()
    is protocol.poker.StartGameCommand -> toDomain()
    is protocol.poker.PauseGameCommand -> toDomain()
    is protocol.poker.ResumeGameCommand -> toDomain()
    is protocol.poker.DealHandCommand -> toDomain()
    is protocol.poker.PlayCommand -> toDomain()
    is protocol.poker.RebuyCommand -> toDomain()
    is protocol.poker.ChangePlayerSeatCommand -> toDomain()
    is protocol.poker.RevealPlayerCardsCommand -> toDomain()
    is protocol.poker.CreateRingGameCommand -> toDomain()
    is protocol.poker.BuyInCommand -> toDomain()
    is protocol.poker.TerminateRingCommand -> toDomain()
    is protocol.poker.TopUpCommand -> toDomain()
    is protocol.poker.UpdateRingGameSettingsCommand -> toDomain()
    is protocol.poker.SitInPlayerCommand -> toDomain()
    is protocol.poker.SitOutPlayerCommand -> toDomain()
}

fun protocol.poker.PokerTableCommand.toDomain(): PokerTableCommand = when (this) {
    is protocol.poker.CreateTournamentCommand -> toDomain()
    is protocol.poker.UpdateTournamentSettingsCommand -> toDomain()
    is protocol.poker.StartGameCommand -> toDomain()
    is protocol.poker.PauseGameCommand -> toDomain()
    is protocol.poker.ResumeGameCommand -> toDomain()
    is protocol.poker.DealHandCommand -> toDomain()
    is protocol.poker.CreateRingGameCommand -> toDomain()
    is protocol.poker.TerminateRingCommand -> toDomain()
    is protocol.poker.UpdateRingGameSettingsCommand -> toDomain()
}
