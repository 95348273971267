package studio.lostjoker.smartdealer.firebase

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth
import dev.gitlive.firebase.remoteconfig.FirebaseRemoteConfig
import dev.gitlive.firebase.remoteconfig.FirebaseRemoteConfigClientException
import dev.gitlive.firebase.remoteconfig.remoteConfig
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import studio.lostjoker.smartdealer.helpers.featureFlagDefaults
import kotlin.math.pow

private const val MAX_RETRIES = 3
private const val BASE_DELAY_MS = 1000L

@OptIn(DelicateCoroutinesApi::class)
fun initFirebaseRemoteConfig() {
    GlobalScope.launch {
        val rc = Firebase.remoteConfig
        rc.setDefaults(*featureFlagDefaults)
        rc.settings {
            minimumFetchIntervalInSeconds = 0
        }
        try {
            fetchAndActivateWithRetry(rc, 1)
        } catch (e: Exception) {
            println("Failed to fetch and activate remote config after retries: ${e.message}")
        }
    }
}

private suspend fun fetchAndActivateWithRetry(rc: FirebaseRemoteConfig, attempt: Int) {
    try {
        println("Attempting fetch and activate (Attempt $attempt)...")
        rc.fetchAndActivate()
        println("Fetch and activate successful.")
    } catch (e: FirebaseRemoteConfigClientException) {
        if (attempt >= MAX_RETRIES) {
            println("Max retries reached. Using default values.")
            throw e
        } else {
            val delayTime = BASE_DELAY_MS * 2.0.pow(attempt - 1).toLong()
            println("Fetch failed: ${e.message}. Retrying in ${delayTime}ms...")
            delay(delayTime)
            fetchAndActivateWithRetry(rc, attempt + 1)
        }
    }
}

suspend fun resolveIdToken(forceRefresh: Boolean): String? {
    return Firebase.auth.currentUser?.getIdToken(forceRefresh)
}
