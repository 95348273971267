@file:UseSerializers(
    UuidStringSerializer::class,
    ByteArrayBase64Serializer::class,
)

package studio.lostjoker.smartdealer.game_management.web.contract

import com.benasher44.uuid.Uuid
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import studio.lostjoker.smartdealer.serialization.ByteArrayBase64Serializer
import studio.lostjoker.smartdealer.serialization.UuidStringSerializer

@Serializable
data class GameCreationResponse(
    val gameCode: String,
    val gameId: Uuid,
)

@Serializable
data class GameExistsResponse(
    val gameCode: String,
    val gameId: Uuid,
)

@Serializable
data class GameManagementApiError(
    val errorCode: GameManagementApiErrorCode? = null,
)

@Serializable
data class PlayerAccountResponse(
    val playerId: String,
    val screenName: String,
    val emailAddress: String?,
    val hasAvatar: Boolean,
)

@Serializable
enum class AvatarSource {
    Social,
    Custom,
}

@Serializable
data class AvatarUploadRequest(
    val avatarSource: AvatarSource,
    val avatarBlob: ByteArray,
)
