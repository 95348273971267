package studio.lostjoker.smartdealer.ui.poker.devices.player

import androidx.lifecycle.Lifecycle
import kotlinx.datetime.Instant
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.Rank
import studio.lostjoker.smartdealer.domain.poker.HighHandRank
import studio.lostjoker.smartdealer.domain.poker.LowHandRank
import studio.lostjoker.smartdealer.domain.poker.RankedHand
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelHandCountRange
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAvailableAction
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.Pot
import studio.lostjoker.smartdealer.domain.poker.model.PotSharePlayerProjection
import studio.lostjoker.smartdealer.domain.poker.model.BlindProgression
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentPrizeSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentRegistrationSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.BettingSize
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Hand
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Payment
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Settings
import studio.lostjoker.smartdealer.ui.poker.enum.BottomSheetState
import studio.lostjoker.smartdealer.ui.poker.enum.DeviceMode
import studio.lostjoker.smartdealer.ui.poker.enum.GameFormat
import kotlin.time.Duration
import kotlin.uuid.Uuid

data class PokerViewState(
    val applicationState: Lifecycle.State = Lifecycle.State.INITIALIZED,
    val connecting: Boolean = true,
    val deviceMode: DeviceMode = DeviceMode.Join,
    val userId: String = "",
    val gameId: Uuid = Uuid.NIL,
    val gameCode: String = "",
    val gameFormat: GameFormat = GameFormat.SitAndGo,
    val gameCreated: Boolean = false,
    val gameStarted: Boolean = false,
    val gameTime: GameTime = GameTime(Instant.DISTANT_PAST, null),
    val gamePaused: Boolean = false,
    val gameEnded: Boolean = false,
    val roundEnded: Boolean = false,
    val playingHand: Boolean = false,
    val tableId: Uuid? = null,
    val players: List<Player> = emptyList(),
    val communityCards: List<DealtRankedCardPlayerView> = emptyList(),
    val lastPlayerActionPerformed: PlayerHandAction? = null,
    val lastPlayerWentAllIn: Boolean = false,
    val lastBettingStack: Fixed2 = 0.toFixed2(),
    val totalPotSize: Fixed2 = 0.toFixed2(),
    val potContributions: List<Pot> = emptyList(),
    val potShares: List<PotSharePlayerProjection> = emptyList(),
    val lastBlindLevelBeforeLateRegEnds: Boolean = false,
    val lastBlindLevelBeforeRebuyEnds: Boolean = false,
    val currentBlindLevel: BlindLevel = BlindLevel.Empty,
    val nextBlindLevel: BlindLevel = BlindLevel.Empty,
    val blindLevelHandCountRange: BlindLevelHandCountRange? = null,
    val handIndex: Int = 0,
    val handCount: Int = 0,
    val winningHandDetails: WinningHandDetails = WinningHandDetails(),
    val settings: Settings = Settings(),
    val bottomSheetState: BottomSheetState? = null,
    val hostPlayerId: String = "",
    val tournamentSettings: TournamentSettings? = null,
    val ringGameSettings: RingGameSettings? = null,
    val pokerSettings: PokerSettings? = null,
    val tablePokerSettings: PokerSettings? = null,
    val payments: List<Payment> = emptyList(),
    val pendingAwayPlayers: List<Int> = emptyList(),
    val showdownTrigger: Boolean = false,
    // player device only
    val host: Boolean = false,
    val playerAtTurnSeat: Int = -1,
    val playerSeat: Int = -1,
    val playerAvailableActions: List<PlayerHandAvailableAction> = emptyList(),
    val playerActionRequestId: Uuid? = null,
    val showCommandErrorMessage: Boolean = false,
    val actionTime: ActionTime = ActionTime(Instant.DISTANT_PAST, Duration.ZERO, null),
    val bettingSize: BettingSize? = null,
    val rankedHighHand: RankedHand<HighHandRank>? = null,
    val rankedLowHand: RankedHand<LowHandRank>? = null,
    val canRebuy: Boolean = false,
    val rebuyRemainingCount: Int = 0,
    val rebuyLevelsLeft: Int = 0,
    val lastHandId: Uuid? = null,
    val lastHand: Hand? = null,
    val requestInAppReview: Boolean = false,
) {
    val fractionDigits = when (gameFormat) {
        GameFormat.SitAndGo -> 0
        GameFormat.RingGame -> 2
    }

    val tableSize = tablePokerSettings?.tableSize ?: 0
    val deckStart = tablePokerSettings?.deckStart ?: Rank.Two
    val blindProgression = tablePokerSettings?.blindProgression ?: BlindProgression.Static(BlindLevel(0.toFixed2(), 0.toFixed2(), 0.toFixed2()))
    val buyIn = when (val registrationSettings = tournamentSettings?.registration) {
        is TournamentRegistrationSettings.ImplicitBuyIn -> registrationSettings.buyIn
        null -> 0.toFixed2()
    }

    val awayCheck = if (tablePokerSettings?.awayPlaying == false) players.count { !it.away } > 1 else true

    data class GameTime(
        val offset: Instant,
        val pauseInstant: Instant?,
    )

    data class ActionTime(
        val offset: Instant,
        val timeout: Duration,
        val pauseInstant: Instant?,
    )

    fun checkHandsLeft(numberOfHands: Int): Boolean {
        if (blindLevelHandCountRange == null) return false
        return blindLevelHandCountRange.endExclusive - blindLevelHandCountRange.start - handCount <= numberOfHands
    }

    private val payoutStructure = when (val prizeSettings = tournamentSettings?.prize) {
        is TournamentPrizeSettings.Payout -> prizeSettings.payoutStructure
        null -> emptyMap()
    }

    fun getActualPayoutStructure(): Map<Int, Double> {
        return payoutStructure.filter { players.size in it.key }.map { it.value }.single()
    }
}
