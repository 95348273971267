package studio.lostjoker.smartdealer.ui.poker.host_game.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Circle
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.Slider
import androidx.compose.material3.SliderDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_settings_blind_level_up_hand
import smartdealer.appshared.generated.resources.poker_settings_blind_level_up_time
import smartdealer.appshared.generated.resources.poker_settings_late_registration
import smartdealer.appshared.generated.resources.poker_settings_late_registration_off
import studio.lostjoker.smartdealer.ui.components.TwoSplitRow
import studio.lostjoker.smartdealer.ui.poker.enum.BlindLevelUp
import studio.lostjoker.smartdealer.ui.poker.host_game.LateRegistrationConfig
import studio.lostjoker.smartdealer.ui.poker.host_game.RebuyConfig
import studio.lostjoker.smartdealer.ui.poker.host_game.SitAndGoSettings
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun LateRegistrationSelector(
    sitAndGoSettings: SitAndGoSettings,
    minBlindLevel: Int = 0,
    maxBlindLevel: Int = 10,
    onChangeLateRegistrationConfig: (lateRegistrationConfig: LateRegistrationConfig) -> Unit,
    onChangeRebuyConfig: (rebuyConfig: RebuyConfig) -> Unit,
) {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {

        TwoSplitRow(
            firstColumnContent = {
                Text(
                    text = stringResource(Res.string.poker_settings_late_registration),
                    style = PokerAppTheme.typography.titleMedium,
                )
            },
            secondColumnContent = {
                Text(
                    text = if (sitAndGoSettings.lateRegistration.numberOfLevels == 0) {
                        stringResource(Res.string.poker_settings_late_registration_off)
                    } else {
                        when (sitAndGoSettings.blindLevelUp) {
                            BlindLevelUp.Time -> stringResource(
                                Res.string.poker_settings_blind_level_up_time,
                                sitAndGoSettings.lateRegistration.numberOfLevels * sitAndGoSettings.blindLevelUpDuration,
                            )

                            BlindLevelUp.Hands -> stringResource(
                                Res.string.poker_settings_blind_level_up_hand,
                                sitAndGoSettings.lateRegistration.numberOfLevels * sitAndGoSettings.blindLevelUpDuration,
                            )
                        }
                    },
                    style = PokerAppTheme.typography.labelLarge,
                )
            },
        )
        Slider(
            colors = SliderDefaults.colors().copy(
                activeTickColor = Color.Transparent,
                inactiveTickColor = Color.Transparent,
                inactiveTrackColor = PokerAppTheme.colors.surfaceVariant,
            ),
            value = sitAndGoSettings.lateRegistration.numberOfLevels.toFloat(),
            steps = (maxBlindLevel - minBlindLevel) + 1,
            valueRange = minBlindLevel.toFloat() ..maxBlindLevel.toFloat(),
            onValueChange = { sliderPosition ->
                onChangeLateRegistrationConfig(sitAndGoSettings.lateRegistration.copy(numberOfLevels = sliderPosition.toInt()))
                if (sliderPosition.toInt() - 1 == 0) onChangeRebuyConfig(RebuyConfig())
            },
            thumb = {
                Icon(
                    modifier = Modifier.size(PokerAppTheme.dimensions.grid_4),
                    imageVector = Icons.Filled.Circle,
                    contentDescription = null,
                    tint = PokerAppTheme.colors.primary,
                )
            },
        )
    }
}
