package studio.lostjoker.smartdealer.domain.poker.game.projection

import com.benasher44.uuid.Uuid
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.poker.model.BotSettings
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings

sealed interface PokerTableCommand

sealed interface PokerPlayerCommand

sealed interface PokerTournamentTableCommand: PokerTableCommand

sealed interface PokerTournamentPlayerCommand: PokerPlayerCommand

sealed interface PokerRingTableCommand: PokerTableCommand

sealed interface PokerRingPlayerCommand: PokerPlayerCommand

sealed interface TableCommand : PokerTournamentPlayerCommand, PokerRingPlayerCommand {
    val tableId: Uuid
}

// Any Game

data object StartGameCommand : PokerTournamentPlayerCommand, PokerTournamentTableCommand, PokerRingPlayerCommand, PokerRingTableCommand

data object PauseGameCommand : PokerTournamentPlayerCommand, PokerTournamentTableCommand, PokerRingPlayerCommand, PokerRingTableCommand

data object ResumeGameCommand : PokerTournamentPlayerCommand, PokerTournamentTableCommand, PokerRingPlayerCommand, PokerRingTableCommand

data class ChangePlayerSeatCommand(
    val playerId: String,
    val seat: Int,
) : PokerTournamentPlayerCommand, PokerRingPlayerCommand

data class SitInPlayerCommand(
    val tableId: Uuid,
    val playerId: String,
    val seat: Int,
) : PokerTournamentPlayerCommand, PokerRingPlayerCommand

data class SitOutPlayerCommand(
    val tableId: Uuid,
    val playerId: String,
    val seat: Int,
) : PokerTournamentPlayerCommand, PokerRingPlayerCommand

// Tournament

data class CreateTournamentCommand(
    val hostPlayerId: String,
    val tournamentSettings: TournamentSettings,
    val pokerSettings: PokerSettings,
    val botSettings: BotSettings,
) : PokerTournamentPlayerCommand, PokerTournamentTableCommand

data class UpdateTournamentSettingsCommand(
    val tournamentSettings: TournamentSettings,
    val pokerSettings: PokerSettings,
) : PokerTournamentPlayerCommand, PokerTournamentTableCommand

data class RegisterPlayerCommand(
    val playerId: String,
    val sessionId: Uuid,
) : PokerTournamentPlayerCommand

data class RebuyCommand(
    val playerId: String,
) : PokerTournamentPlayerCommand

// Ring

data class CreateRingGameCommand(
    val hostPlayerId: String,
    val ringGameSettings: RingGameSettings,
    val pokerSettings: PokerSettings,
    val botSettings: BotSettings,
) : PokerRingPlayerCommand, PokerRingTableCommand

data class BuyInCommand(
    val playerId: String,
    val sessionId: Uuid,
) : PokerRingPlayerCommand

data class TopUpCommand(
    val playerId: String,
    val amount: Fixed2,
) : PokerRingPlayerCommand

data class UpdateRingGameSettingsCommand(
    val ringGameSettings: RingGameSettings,
    val pokerSettings: PokerSettings,
) : PokerRingPlayerCommand, PokerRingTableCommand

data object TerminateRingCommand : PokerRingPlayerCommand, PokerRingTableCommand

// Table

data class DealHandCommand(
    override val tableId: Uuid,
): TableCommand, PokerTournamentTableCommand, PokerRingTableCommand

data class PlayCommand(
    override val tableId: Uuid,
    val requestId: Uuid,
    val action: PlayerHandAction,
): TableCommand

data class RevealPlayerCardsCommand(
    override val tableId: Uuid,
    val seat: Int,
    val cardIndexes: List<Int>,
) : TableCommand

