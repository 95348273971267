package studio.lostjoker.smartdealer.ui.poker.devices.table

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import cafe.adriel.voyager.core.model.rememberScreenModel
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.core.screen.ScreenKey
import cafe.adriel.voyager.core.screen.uniqueScreenKey
import cafe.adriel.voyager.navigator.LocalNavigator
import com.benasher44.uuid.Uuid
import studio.lostjoker.smartdealer.notification.LocalNotificationService
import studio.lostjoker.smartdealer.preferences.PreferenceEnums
import studio.lostjoker.smartdealer.preferences.rememberUserPreferences
import studio.lostjoker.smartdealer.ui.poker.devices.player.PokerViewState
import studio.lostjoker.smartdealer.ui.poker.enum.DeviceMode
import studio.lostjoker.smartdealer.ui.poker.join_game.JsJoinGameScreen

class JsTableDeviceScreen(
    private val gameCode: String,
    private val gameId: Uuid,
    private val gameplayBaseUrl: String,
    private val apiBaseUrl: String,
) : Screen {

    override val key: ScreenKey = uniqueScreenKey

    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val notificationService = LocalNotificationService.current
        val userPreferences = rememberUserPreferences(PreferenceEnums.Storage.Poker.key)

        // TODO: watcher is a provisional name for userId on WASM. Ideally it shouldn't have any
        val tableDeviceViewModel: TableDeviceViewModel = rememberScreenModel(key) {
            TableDeviceViewModel(
                gameId = gameId,
                gameCode = gameCode,
                deviceMode = DeviceMode.Join,
                userId = "watcher",
                notificationService = notificationService,
                gameplayBaseUrl = gameplayBaseUrl,
                apiBaseUrl = apiBaseUrl,
                preferenceDelegate = userPreferences,
            )
        }

        val stateFlow = tableDeviceViewModel.state.collectAsState(PokerViewState())
        val state = stateFlow.value

        TableDeviceScreenView(
            state = state,
            deal = tableDeviceViewModel::deal,
            changeVerticalDisplay = tableDeviceViewModel::changeVerticalDisplay,
            changeTableFelt = tableDeviceViewModel::changeTableFelt,
            changeCardStyle = tableDeviceViewModel::changeCardStyle,
            changeCardBackStyle = tableDeviceViewModel::changeCardBackStyle,
            changeBottomSheetState = tableDeviceViewModel::changeBottomSheetState,
            updatePlayerAccount = tableDeviceViewModel::updatePlayerAccount,
            updatePaymentTransfer = tableDeviceViewModel::updatePaymentTransfer,
            startTournament = tableDeviceViewModel::startTournament,
            updateTournamentSettings = tableDeviceViewModel::updateTournamentSettings,
            pause = tableDeviceViewModel::pauseTournament,
            resume = tableDeviceViewModel::resumeTournament,
            leaveGame = {
                tableDeviceViewModel.leaveGame()
                navigator?.push(JsJoinGameScreen(gameplayBaseUrl, apiBaseUrl))
            },
            updateWinningHandDetails = tableDeviceViewModel::updateWinningHandDetails,
        )
    }
}
