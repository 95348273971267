package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_devices_game_paused
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun GamePaused(
    device: Device,
) {
    Column(
        modifier = Modifier
            .background(Color.Black.copy(alpha = 0.8f))
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        Text(
            text = stringResource(Res.string.poker_devices_game_paused),
            color = Color.White,
            style = when (device) {
                Device.Player -> PokerAppTheme.typography.titleMedium
                Device.Table -> PokerAppTheme.typography.displayMedium
            },
            textAlign = TextAlign.Center,
        )
    }
}
