package studio.lostjoker.smartdealer.helpers.sound

sealed class AudioFiles {
    enum class Poker(val path: String) {
        DealCards("files/poker/deal-cards.mp3"),
        FlipCards("files/poker/flip-cards.mp3"),
        PlayerAtTurn("files/poker/player-at-turn.mp3"),
        PostBlinds("files/poker/any-bet.mp3"),
        Check("files/poker/check.mp3"),
        Call("files/poker/any-bet.mp3"),
        Bet("files/poker/any-bet.mp3"),
        Raise("files/poker/any-bet.mp3"),
        Fold("files/poker/fold.mp3"),
        AllIn("files/poker/all-in.mp3"),
        Showdown("files/poker/showdown.mp3"),
        Clock("files/poker/clock-ticking.mp3"),
    }
}
