package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.text.selection.LocalTextSelectionColors
import androidx.compose.foundation.text.selection.TextSelectionColors
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material3.Icon
import androidx.compose.material3.Slider
import androidx.compose.material3.SliderDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Color.Companion.Transparent
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.layout
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.platform.LocalTextToolbar
import androidx.compose.ui.platform.TextToolbar
import androidx.compose.ui.platform.TextToolbarStatus
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.poker.model.BettingLimit
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.BettingSize
import studio.lostjoker.smartdealer.ui.poker.enum.Postflop
import studio.lostjoker.smartdealer.ui.poker.enum.Preflop
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun BetSlider(
    bettingLimit: BettingLimit,
    blindLevel: BlindLevel,
    communityCardsDealt: Boolean,
    totalStack: Fixed2,
    lastBettingStack: Fixed2,
    totalPotSize: Fixed2,
    bettingSize: BettingSize,
    fractionDigits: Int = 0,
    changeBettingSize: (sliderPosition: Float) -> Unit = {},
    textFieldFocused: (isFocused: Boolean) -> Unit = {},
) {
    val userLanguage = getUserLanguage()
    val pattern = remember { Regex("^\\d+[.,]?\\d*\$") }
    val focusManager = LocalFocusManager.current
    val keyboardController = LocalSoftwareKeyboardController.current

    val minPrecision = when (fractionDigits) {
        0 -> Fixed2.intPrecision
        else -> Fixed2.minPrecision
    }

    var numberValue by remember(bettingSize) { mutableStateOf(bettingSize.amount.asDouble().format(digits = fractionDigits, appLanguage = userLanguage)) }
    val customTextSelectionColors = TextSelectionColors(handleColor = Transparent, backgroundColor = Transparent)

    val preflopShortcuts = when (bettingLimit) {
        BettingLimit.NoLimit -> Preflop.entries - Preflop.Pot
        BettingLimit.PotLimit -> {
            if (totalStack <= bettingSize.max) {
                Preflop.entries - Preflop.Pot
            } else {
                Preflop.entries - Preflop.AllIn
            }
        }
        BettingLimit.FixedLimit -> TODO()
    }

    val postflopShortcuts = when (bettingLimit) {
        BettingLimit.NoLimit -> Postflop.entries - Postflop.Pot
        BettingLimit.PotLimit -> {
            if (totalStack <= bettingSize.max) {
                Postflop.entries - Postflop.Pot
            } else {
                Postflop.entries - Postflop.AllIn
            }
        }
        BettingLimit.FixedLimit -> TODO()
    }

    Row(
        verticalAlignment = Alignment.Bottom,
        horizontalArrangement = Arrangement.Center,
    ) {
        Column(
            modifier = Modifier
                .height(PokerAppTheme.dimensions.playerDeviceBetSliderWidth)
                .padding(end = PokerAppTheme.dimensions.grid_0_5),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(5.dp, Alignment.Top),
        ) {
            if (!communityCardsDealt) {
                preflopShortcuts.forEach { shortcut ->
                    Box(
                        modifier = Modifier
                            .size(PokerAppTheme.dimensions.grid_4_5)
                            .background(
                                color = Color.DarkGray.copy(alpha = 0.9f),
                                shape = CircleShape,
                            )
                            .pointerInput(shortcut) {
                                detectTapGestures(
                                    onTap = {
                                        when (shortcut) {
                                            Preflop.AllIn, Preflop.Pot -> changeBettingSize(bettingSize.max.asFloat())
                                            else -> changeBettingSize(
                                                minOf(
                                                    bettingSize.max.asFloat(),
                                                    lastBettingStack.asFloat() * shortcut.value,
                                                ).toFixed2().roundTo(minPrecision).asFloat(),
                                            )
                                        }
                                    },
                                )
                            },
                        contentAlignment = Alignment.Center,
                    ) {
                        Text(
                            text = shortcut.label,
                            style = PokerAppTheme.typography.labelSmall,
                            color = Color.White,
                        )
                    }
                }
            } else {
                postflopShortcuts.forEach { shortcut ->
                    Box(
                        modifier = Modifier
                            .size(PokerAppTheme.dimensions.grid_4_5)
                            .background(
                                color = Color.DarkGray.copy(alpha = 0.9f),
                                shape = CircleShape,
                            )
                            .pointerInput(shortcut) {
                                detectTapGestures(
                                    onTap = {
                                        when (shortcut) {
                                            Postflop.AllIn, Postflop.Pot -> changeBettingSize(bettingSize.max.asFloat())
                                            else -> changeBettingSize(
                                                minOf(
                                                    bettingSize.max.asFloat(),
                                                    betOrRaiseToPotSize(
                                                        potSizedPercentage = shortcut.value,
                                                        lastBet = lastBettingStack,
                                                        potSizeBeforeLastBet = totalPotSize - lastBettingStack,
                                                    ),
                                                ).toFixed2().roundTo(minPrecision).asFloat(),
                                            )
                                        }
                                    },
                                )
                            },
                        contentAlignment = Alignment.Center,
                    ) {
                        Text(
                            text = shortcut.label,
                            style = PokerAppTheme.typography.labelSmall,
                            color = Color.White,
                        )
                    }
                }
            }
        }
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
        ) {
            CompositionLocalProvider(
                LocalTextToolbar provides EmptyTextToolbar,
                LocalTextSelectionColors provides customTextSelectionColors,
            ) {
                BasicTextField(
                    modifier = Modifier
                        .padding(bottom = PokerAppTheme.dimensions.grid_0_5)
                        .onFocusChanged { focusState ->
                            textFieldFocused(focusState.isFocused)
                            numberValue = if (focusState.isFocused) {
                                bettingSize.amount.asDouble().format(digits = fractionDigits, appLanguage = userLanguage)
                            } else {
                                numberValue.toFloatOrNull()?.let { value ->
                                    if (value >= bettingSize.min.asFloat() && value <= bettingSize.max.asFloat()) {
                                        changeBettingSize(value)
                                        return@onFocusChanged
                                    }
                                }
                                bettingSize.amount.asDouble().format(digits = fractionDigits, appLanguage = userLanguage)
                            }
                        },
                    value = numberValue,
                    onValueChange = {
                        if (it.isEmpty() || it.matches(pattern)) {
                            numberValue = it
                        }
                    },
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Number,
                        imeAction = ImeAction.Done,
                    ),
                    keyboardActions = KeyboardActions(
                        onDone = {
                            keyboardController?.hide()
                            focusManager.clearFocus()
                        },
                    ),
                    singleLine = true,
                    textStyle = PokerAppTheme.typography.labelMedium.copy(textAlign = TextAlign.Center),
                    decorationBox = { innerTextField ->
                        Row(
                            modifier = Modifier
                                .size(PokerAppTheme.dimensions.grid_6)
                                .background(
                                    color = Color.White,
                                    shape = RoundedCornerShape(size = 10.dp),
                                ),
                            verticalAlignment = Alignment.CenterVertically,
                        ) {
                            innerTextField()
                        }
                    },
                )
            }
            Box(
                modifier = Modifier
                    .rotate(-90f)
                    .layout90Rotated(),
                contentAlignment = Alignment.Center,
            ) {
                Row(
                    modifier = Modifier
                        .height(PokerAppTheme.dimensions.grid_6)
                        .width(PokerAppTheme.dimensions.playerDeviceBetSliderWidth)
                        .clip(
                            RoundedCornerShape(
                                topStart = PokerAppTheme.dimensions.grid_2_5,
                                topEnd = PokerAppTheme.dimensions.grid_2_5,
                                bottomStart = PokerAppTheme.dimensions.grid_2_5,
                                bottomEnd = PokerAppTheme.dimensions.grid_2_5,
                            ),
                        )
                        .background(Color.DarkGray.copy(alpha = 0.9f)),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    Icon(
                        modifier = Modifier
                            .rotate(-90f)
                            .padding(PokerAppTheme.dimensions.grid_0_5)
                            .pointerInput(bettingSize) {
                                detectTapGestures(
                                    onTap = {
                                        val value = bettingSize.amount - blindLevel.bigBlind
                                        if (value >= bettingSize.min) {
                                            changeBettingSize(value.asFloat())
                                        } else {
                                            changeBettingSize(bettingSize.min.asFloat())
                                        }
                                    },
                                )
                            },
                        imageVector = Icons.Filled.Remove,
                        contentDescription = "Minus",
                        tint = Color.Gray,
                    )
                    Slider(
                        modifier = Modifier
                            .fillMaxSize()
                            .weight(1f),
                        value = bettingSize.amount.asFloat(),
                        onValueChange = { changeBettingSize(it) },
                        colors = SliderDefaults.colors(
                            thumbColor = Color.LightGray,
                            activeTrackColor = Color.LightGray,
                            activeTickColor = Color.LightGray,
                            inactiveTrackColor = Color.Gray,
                            inactiveTickColor = Color.Gray,
                        ),
                        steps = if ((bettingSize.max - bettingSize.min) > blindLevel.bigBlind) {
                            (((bettingSize.max - bettingSize.min) / blindLevel.bigBlind) - 1).toInt()
                        } else {
                            1
                        },
                        valueRange = bettingSize.min.asFloat()..bettingSize.max.asFloat(),
                    )
                    Icon(
                        modifier = Modifier
                            .rotate(-90f)
                            .padding(PokerAppTheme.dimensions.grid_0_5)
                            .pointerInput(bettingSize) {
                                detectTapGestures(
                                    onTap = {
                                        val value = bettingSize.amount + blindLevel.bigBlind
                                        if (value <= bettingSize.max) {
                                            changeBettingSize(value.asFloat())
                                        } else {
                                            changeBettingSize(bettingSize.max.asFloat())
                                        }
                                    },
                                )
                            },
                        imageVector = Icons.Filled.Add,
                        contentDescription = "Plus",
                        tint = Color.Gray,
                    )
                }
            }
        }
    }
}

fun betOrRaiseToPotSize(potSizedPercentage: Float, lastBet: Fixed2, potSizeBeforeLastBet: Fixed2): Float {
    return if (lastBet != 0.toFixed2()) {
        potSizedPercentage * (2f * lastBet.asFloat() + potSizeBeforeLastBet.asFloat()) + lastBet.asFloat()
    } else {
        potSizedPercentage * potSizeBeforeLastBet.asFloat()
    }
}

fun Modifier.layout90Rotated() =
    layout { measurable, constraints ->
        val placeable = measurable.measure(constraints)
        layout(placeable.height, placeable.width) {
            placeable.place(-(placeable.width - placeable.height) / 2, (placeable.width - placeable.height) / 2)
        }
    }

object EmptyTextToolbar : TextToolbar {
    override val status: TextToolbarStatus = TextToolbarStatus.Hidden

    override fun hide() {}

    override fun showMenu(
        rect: Rect,
        onCopyRequested: (() -> Unit)?,
        onPasteRequested: (() -> Unit)?,
        onCutRequested: (() -> Unit)?,
        onSelectAllRequested: (() -> Unit)?,
    ) {
    }
}
