package studio.lostjoker.smartdealer

import androidx.compose.runtime.Composable
import androidx.compose.ui.text.intl.Locale
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.language_english
import smartdealer.appshared.generated.resources.language_portuguese
import studio.lostjoker.smartdealer.preferences.PreferenceEnums
import studio.lostjoker.smartdealer.preferences.rememberUserPreferences

data class AppLanguage(val name: String, val languageCode: String, val countryCode: String = "")

@Composable
fun getAppLanguages(): List<AppLanguage> {
    return listOf(
        AppLanguage(stringResource(Res.string.language_english), "en", "US"),
        AppLanguage(stringResource(Res.string.language_portuguese), "pt", "BR"),
    )
}

fun getDefaultLanguage(languages: List<AppLanguage>, appLocale: String): AppLanguage {
    // TODO: Not sure if best way to define english as main language for non-en/pt users
    return languages.firstOrNull { it.languageCode == appLocale } ?: languages.first()
}

@Composable
fun getUserLanguage(): AppLanguage {
    val userPreferences = rememberUserPreferences(PreferenceEnums.Storage.Poker.key)
    val preferredLanguage = userPreferences.getString(PreferenceEnums.Settings.Language.key, Locale.current.language)
    return getDefaultLanguage(getAppLanguages(), preferredLanguage ?: "en")
}
