package studio.lostjoker.smartdealer.helpers.sound

import androidx.compose.runtime.Composable

class JsAudioPlayer : AudioPlayer {
    override fun playSound(resource: String, soundEnabled: Boolean) {

    }
}

@Composable
actual fun createAudioPlayer() :AudioPlayer {
    return JsAudioPlayer()
}
