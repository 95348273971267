package studio.lostjoker.smartdealer.domain.poker.game.projection

import kotlinx.datetime.Instant
import studio.lostjoker.smartdealer.domain.Card
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.Seat
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelHandCountRange
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.Positions
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import kotlin.time.Duration
import kotlin.uuid.Uuid

data class PokerClientSnapshot(
    val currentTournament: TournamentSnapshot?,
    val currentRing: RingSnapshot?,
    val currentTable: TableSnapshot?,
    val currentHand: HandSnapshot?,
    val currentRound: RoundSnapshot?,
)

data class TournamentSnapshot(
    val created: Boolean,
    val started: Boolean,
    val playerIds: Map<Uuid, String>,
    val paused: Boolean,
    val startInstant: Instant?,
    val pauseInstant: Instant?,
    val blindLevel: BlindLevel,
    val handCount: Int,
    val handCountRange: BlindLevelHandCountRange,
    val lateRegistrationLevelsLeft: Int,
    val rebuyLevelsLeft: Int,
    val ended: Boolean,
    val ranks: Map<Uuid, Int>,
    val prizes: Map<Uuid, Fixed2>,
    val rebuys: Map<Uuid, Int>,
    val hostPlayerId: String,
    val tournamentSettings: TournamentSettings,
    val pokerSettings: PokerSettings,
    // player only
    val rebuyRemainingCount: Int,
)

data class RingSnapshot(
    val created: Boolean,
    val started: Boolean,
    val playerIds: Map<Uuid, String>,
    val paused: Boolean,
    val startInstant: Instant?,
    val pauseInstant: Instant?,
    val blindLevel: BlindLevel,
    val handCount: Int,
    val ended: Boolean,
    val buyIn: Map<Uuid, Fixed2>,
    val hostPlayerId: String,
    val ringGameSettings: RingGameSettings,
    val pokerSettings: PokerSettings,
)

data class TableSnapshot(
    val seats: List<Seat>,
    val playerSeat: Int,
    val tableId: Uuid,
    val lastHandId: Uuid?,
    val stacks: List<PlayerSnapshotStack>,
    val pokerSettings: PokerSettings,
)

data class HandSnapshot(
    val positions: Positions,
    val seats: List<HandSnapshotSeat>,
    val communityCards: List<Card.Ranked>,
    val ended: Boolean,
    val stacks: List<PlayerSnapshotStack>,
)

data class HandSnapshotSeat(
    val seat: Int,
    val holeCards: List<DealtRankedCardPlayerView>,
    val folded: Boolean,
)

data class PlayerSnapshotStack(
    val seat: Int,
    val stack: Fixed2,
)

data class RoundSnapshot(
    val seats: List<RoundSnapshotSeat>,
    val totalPotSize: Fixed2,
)

data class RoundSnapshotSeat(
    val seat: Int,
    val lastAction: PlayerHandAction,
    val remainingStack: Fixed2,
    val bettingStack: Fixed2,
)
