package studio.lostjoker.smartdealer.ui.poker.devices

import androidx.lifecycle.Lifecycle
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.datetime.Instant
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.Player.Bot
import studio.lostjoker.smartdealer.domain.Seat
import studio.lostjoker.smartdealer.domain.maxOf
import studio.lostjoker.smartdealer.domain.poker.game.projection.ActionPerformedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.ActionRequestedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.BlindLevelChangedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.BlindsPostedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.CommunityCardsDealtEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GameEndedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GamePausedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GameResumedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.GameStartedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.HandEndedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.HandStartedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerBlindMissedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerBoughtInEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerCardsDealtEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerCardsRevealedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerEliminatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerRebuyCompletedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerRegisteredEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSatInEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSatOutEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSeatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerStackUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerToppedUpEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerClientEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerClientSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.RingCreatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.RingGameSettingsUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundEndedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundStartedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.TableCreatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.TableSettingsUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.TournamentCreatedEvent
import studio.lostjoker.smartdealer.domain.poker.game.projection.TournamentSettingsUpdatedEvent
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAvailableAction
import studio.lostjoker.smartdealer.domain.poker.model.TournamentRebuyConfig
import studio.lostjoker.smartdealer.domain.poker.model.TournamentRegistrationSettings
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.notification.NotificationService
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.BettingSize
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.Position
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails
import studio.lostjoker.smartdealer.ui.poker.devices.player.PokerViewState
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.generatePayments
import studio.lostjoker.smartdealer.ui.poker.enum.GameFormat
import kotlin.time.Duration

abstract class PokerViewModel(notificationService: NotificationService) {

    abstract val userId: String?

    protected var _state = MutableStateFlow(PokerViewState())
    private val _notificationService = notificationService

    private fun notifyPlayer(title: String, content: String) {
        _notificationService.notify(title, content)
    }

    protected fun updateStateOnSnapshot(snapshot: PokerClientSnapshot) {
        snapshot.currentTournament?.let { t ->
            val players = t.playerIds.map {
                Player(
                    sessionId = it.key,
                    playerId = it.value,
                    rank = t.ranks[it.key] ?: -1,
                    prize = t.prizes[it.key] ?: 0.toFixed2(),
                    rebuy = t.rebuys[it.key] ?: 0,
                )
            }
            _state.update { s ->
                s.copy(
                    gameFormat = GameFormat.SitAndGo,
                    gameCreated = t.created,
                    gameStarted = t.started,
                    gameEnded = t.ended,
                    gamePaused = t.pauseInstant != null,
                    gameTime = PokerViewState.GameTime(t.startInstant ?: Instant.DISTANT_PAST, t.pauseInstant),
                    actionTime = PokerViewState.ActionTime(Instant.DISTANT_PAST, Duration.ZERO, t.pauseInstant),
                    currentBlindLevel = t.blindLevel,
                    handIndex = t.handCount,
                    handCount = t.handCount - t.handCountRange.start,
                    blindLevelHandCountRange = t.handCountRange,
                    lastBlindLevelBeforeLateRegEnds = t.lateRegistrationLevelsLeft == 1,
                    lastBlindLevelBeforeRebuyEnds = t.rebuyLevelsLeft == 1,
                    rebuyRemainingCount = t.rebuyRemainingCount,
                    rebuyLevelsLeft = t.rebuyLevelsLeft,
                    players = players,
                    hostPlayerId = t.hostPlayerId,
                    tournamentSettings = t.tournamentSettings,
                    pokerSettings = t.pokerSettings,
                    tablePokerSettings = t.pokerSettings,
                    payments = generatePayments(
                        gameFormat = GameFormat.SitAndGo,
                        players = players,
                        buyIn = when (val registrationSettings = t.tournamentSettings.registration) {
                            is TournamentRegistrationSettings.ImplicitBuyIn -> registrationSettings.buyIn
                        },
                    ),
                    host = userId == t.hostPlayerId,
                )
            }
        }
        snapshot.currentRing?.let { r ->
            val players = r.playerIds.map {
                Player(
                    sessionId = it.key,
                    playerId = it.value,
                    buyIn = r.buyIn[it.key] ?: 0.toFixed2(),
                )
            }
            _state.update { s ->
                s.copy(
                    gameFormat = GameFormat.RingGame,
                    gameCreated = r.created,
                    gameStarted = r.started,
                    gameEnded = r.ended,
                    gamePaused = r.pauseInstant != null,
                    gameTime = PokerViewState.GameTime(r.startInstant ?: Instant.DISTANT_PAST, r.pauseInstant),
                    actionTime = PokerViewState.ActionTime(Instant.DISTANT_PAST, Duration.ZERO, r.pauseInstant),
                    currentBlindLevel = r.blindLevel,
                    handIndex = r.handCount,
                    players = players,
                    hostPlayerId = r.hostPlayerId,
                    ringGameSettings = r.ringGameSettings,
                    pokerSettings = r.pokerSettings,
                    tablePokerSettings = r.pokerSettings,
                    payments = generatePayments(
                        gameFormat = GameFormat.RingGame,
                        players = players,
                        buyIn = 0.toFixed2(),
                    ),
                    host = userId == r.hostPlayerId,
                )
            }
        }
        snapshot.currentTable?.let { t ->
            _state.update { s ->
                s.copy(
                    players = s.players.map { playerInState ->
                        val seat = t.seats.indexOfFirst { it is Seat.Occupied && it.player.id == playerInState.playerId }
                        val remainingStack = t.stacks.singleOrNull { it.seat == seat }?.stack ?: 0.toFixed2()
                        playerInState.copy(
                            seat = seat,
                            remainingStack = remainingStack,
                            eliminated = remainingStack == 0.toFixed2(),
                            bot = (t.seats[seat] as Seat.Occupied).player is Bot,
                            away = (t.seats[seat] as Seat.Occupied).away,
                        )
                    },
                    playerSeat = t.playerSeat,
                    tableId = t.tableId,
                    tablePokerSettings = t.pokerSettings,
                    lastHandId = t.lastHandId,
                )
            }
        }
        snapshot.currentHand?.let { h ->
            _state.update { s ->
                s.copy(
                    playingHand = !h.ended,
                    players = s.players
                        .map { player ->
                            h.seats.singleOrNull { it.seat == player.seat }
                                ?.let { result ->
                                    player.copy(
                                        holeCards = result.holeCards,
                                        folded = result.folded,
                                        position = when (player.seat) {
                                            h.positions.dealerSeat -> Position.D
                                            h.positions.smallBlindSeat -> Position.SB
                                            h.positions.bigBlindSeat -> Position.BB
                                            h.positions.utgSeat -> Position.UTG
                                            h.positions.utg1Seat -> Position.UTG1
                                            h.positions.mp1Seat -> Position.MP1
                                            h.positions.mp2Seat -> Position.MP2
                                            h.positions.mp3Seat -> Position.MP3
                                            h.positions.hijackSeat -> Position.HJ
                                            h.positions.cutoffSeat -> Position.CO
                                            else -> null
                                        },
                                    )
                                }
                                ?: player
                        }
                        .map { player ->
                            h.stacks.singleOrNull { it.seat == player.seat }
                                ?.let { result ->
                                    player.copy(
                                        remainingStack = result.stack,
                                    )
                                }
                                ?: player
                        },
                    communityCards = h.communityCards.map { DealtRankedCardPlayerView.FaceUp(it) },
                )
            }
        }
        snapshot.currentRound?.let { r ->
            _state.update { s ->
                s.copy(
                    players = s.players
                        .map { player ->
                            r.seats.singleOrNull { it.seat == player.seat }
                                ?.let { result ->
                                    player.copy(
                                        action = result.lastAction,
                                        remainingStack = result.remainingStack,
                                        bettingStack = result.bettingStack,
                                    )
                                }
                                ?: player
                        },
                    lastBettingStack = r.seats.maxOfOrNull { it.bettingStack } ?: 0.toFixed2(),
                    totalPotSize = r.totalPotSize,
                )
            }
        }
    }

    protected fun updateStateOnEvent(event: PokerClientEvent) = when (event) {
        is TournamentCreatedEvent -> {
            _state.update {
                it.copy(
                    gameFormat = GameFormat.SitAndGo,
                    gameCreated = true,
                    hostPlayerId = event.hostPlayerId,
                    host = userId == event.hostPlayerId,
                    tournamentSettings = event.format,
                    pokerSettings = event.settings,
                    tablePokerSettings = event.settings,
                    rebuyRemainingCount = when (val rebuyConfig = event.format.rebuy) {
                        is TournamentRebuyConfig.Disabled -> 0
                        is TournamentRebuyConfig.Enabled -> rebuyConfig.rebuyLimit
                    },
                )
            }
        }

        is TournamentSettingsUpdatedEvent -> {
            _state.update { state ->
                state.copy(
                    tournamentSettings = event.format,
                    pokerSettings = event.settings,
                    rebuyRemainingCount = event.rebuyRemainingCount[userId] ?: state.rebuyRemainingCount,
                )
            }
        }

        is RingCreatedEvent -> {
            _state.update {
                it.copy(
                    gameFormat = GameFormat.RingGame,
                    gameCreated = true,
                    hostPlayerId = event.hostPlayerId,
                    host = userId == event.hostPlayerId,
                    ringGameSettings = event.format,
                    pokerSettings = event.settings,
                    tablePokerSettings = event.settings,
                )
            }
        }

        is RingGameSettingsUpdatedEvent -> {
            _state.update { state ->
                state.copy(
                    ringGameSettings = event.format,
                    pokerSettings = event.settings,
                )
            }
        }

        is PlayerRegisteredEvent -> {
            _state.update {
                it.copy(
                    players = it.players + Player(
                        sessionId = event.sessionId,
                        playerId = event.player.id,
                        bot = event.player is Bot,
                    ),
                )
            }
        }

        is PlayerBoughtInEvent -> {
            _state.update {
                it.copy(
                    players = it.players + Player(
                        sessionId = event.sessionId,
                        playerId = event.player.id,
                        bot = event.player is Bot,
                    ),
                )
            }
        }

        is TableCreatedEvent -> {
            _state.update {
                it.copy(
                    tableId = event.tableId,
                )
            }
        }

        is TableSettingsUpdatedEvent -> {
            _state.update { state ->
                state.copy(
                    tablePokerSettings = event.settings,
                )
            }
        }

        is PlayerSeatedEvent -> {
            if (event.player.id == userId) {
                _state.update {
                    it.copy(
                        tableId = event.tableId,
                        playerSeat = event.seat,
                    )
                }
            }
            _state.update { state ->
                state.copy(
                    players = state.players.map { playerInState ->
                        val buyIn = when (state.gameFormat) {
                            GameFormat.SitAndGo -> playerInState.buyIn
                            GameFormat.RingGame -> event.stack
                        }
                        if (playerInState.playerId == event.player.id) {
                            playerInState.copy(
                                seat = event.seat,
                                remainingStack = event.stack,
                                buyIn = buyIn,
                            )
                        } else {
                            playerInState.copy(
                                buyIn = buyIn,
                            )
                        }
                    }
                )
            }
        }

        is PlayerSatOutEvent -> {
            _state.update { state ->
                state.copy(
                    players = state.players.map { player ->
                        if (player.seat == event.seat && player.folded) {
                            player.copy(
                                away = true,
                            )
                        } else {
                            player
                        }
                    },
                    pendingAwayPlayers = if (state.players.singleOrNull { it.seat == event.seat }?.folded == false) {
                        state.pendingAwayPlayers + event.seat
                    }
                    else {
                        state.pendingAwayPlayers
                    },
                )
            }
        }

        is PlayerSatInEvent -> {
            _state.update { state ->
                state.copy(
                    players = state.players.map { player ->
                        if (player.seat == event.seat) {
                            player.copy(
                                away = false,
                            )
                        } else {
                            player
                        }
                    },
                    pendingAwayPlayers = state.pendingAwayPlayers.filter { it != event.seat },
                )
            }
        }

        is GameStartedEvent -> {
            _state.update { state ->
                state.copy(
                    gameStarted = true,
                    gameTime = PokerViewState.GameTime(event.instant, null),
                )
            }
        }

        is GamePausedEvent -> {
            _state.update { state ->
                state.copy(
                    gamePaused = true,
                    gameTime = state.gameTime.copy(pauseInstant = event.instant),
                    actionTime = state.actionTime.copy(pauseInstant = event.instant),
                )
            }
        }

        is GameResumedEvent -> {
            _state.update { state ->
                val pauseInstant = state.gameTime.pauseInstant
                state.copy(
                    gamePaused = false,
                    gameTime = PokerViewState.GameTime(state.gameTime.offset + (event.instant - pauseInstant!!), null),
                    actionTime = PokerViewState.ActionTime(state.actionTime.offset + (event.instant - pauseInstant), state.actionTime.timeout, null),
                )
            }
        }

        is PlayerRebuyCompletedEvent -> {
            if (event.sessionId == _state.value.players.singleOrNull { it.playerId == userId }?.sessionId) {
                _state.update { state ->
                    state.copy(
                        rebuyRemainingCount = event.rebuyRemainingCount,
                    )
                }
            }
            _state.update { state ->
                state.copy(
                    players = state.players.map { player ->
                        if (player.sessionId == event.sessionId) {
                            player.copy(
                                rebuy = player.rebuy + 1,
                                eliminated = false,
                            )
                        } else {
                            player
                        }
                    },
                )
            }
        }

        is PlayerToppedUpEvent -> {
            _state.update { state ->
                state.copy(
                    players = state.players.map { player ->
                        if (player.sessionId == event.sessionId) {
                            player.copy(
                                remainingStack = player.remainingStack + event.amount,
                                buyIn = player.buyIn + event.amount,
                            )
                        } else {
                            player
                        }
                    },
                )
            }
        }

        is PlayerStackUpdatedEvent -> {
            _state.update { state ->
                state.copy(
                    players = state.players.map { player ->
                        if (player.playerId == event.playerId) {
                            player.copy(remainingStack = event.stack)
                        } else {
                            player
                        }
                    },
                )
            }
        }

        is BlindLevelChangedEvent -> {
            _state.update { state ->
                state.copy(
                    currentBlindLevel = if (state.currentBlindLevel == BlindLevel.Empty) event.blindLevel else state.currentBlindLevel,
                    nextBlindLevel = if (state.currentBlindLevel == BlindLevel.Empty) state.nextBlindLevel else event.blindLevel,
                    blindLevelHandCountRange = event.handCountRange,
                    lastBlindLevelBeforeLateRegEnds = event.lateRegistrationLevelsLeft == 1,
                    lastBlindLevelBeforeRebuyEnds = event.rebuyLevelsLeft == 1,
                    rebuyLevelsLeft = event.rebuyLevelsLeft,
                )
            }
        }

        is PlayerEliminatedEvent -> {
            _state.update { state ->
                state.copy(
                    players = state.players.map { player ->
                        if (player.sessionId == event.sessionId) {
                            player.copy(rank = event.rank, prize = event.prize, rebuy = event.rebuy)
                        } else {
                            player
                        }
                    },
                )
            }
        }

        is GameEndedEvent -> {
            _state.update { state ->
                state.copy(
                    gameEnded = true,
                    requestInAppReview = state.players.none { it.bot },
                    payments = generatePayments(state.gameFormat, state.players, state.buyIn),
                )
            }
        }

        is HandStartedEvent -> {
            _state.update { state ->
                val players = state.players
                    .map { player ->
                        player.copy(
                            showdown = false,
                            folded = false,
                            holeCards = emptyList(),
                            position = when (player.seat) {
                                event.positions.dealerSeat -> Position.D
                                event.positions.smallBlindSeat -> Position.SB
                                event.positions.bigBlindSeat -> Position.BB
                                event.positions.utgSeat -> Position.UTG
                                event.positions.utg1Seat -> Position.UTG1
                                event.positions.mp1Seat -> Position.MP1
                                event.positions.mp2Seat -> Position.MP2
                                event.positions.mp3Seat -> Position.MP3
                                event.positions.hijackSeat -> Position.HJ
                                event.positions.cutoffSeat -> Position.CO
                                else -> null
                            },
                        )
                    }
                val newBlindLevel = state.nextBlindLevel != BlindLevel.Empty && state.nextBlindLevel != state.currentBlindLevel
                state.copy(
                    players = players,
                    communityCards = emptyList(),
                    rankedHighHand = null,
                    rankedLowHand = null,
                    playingHand = true,
                    winningHandDetails = WinningHandDetails(),
                    potContributions = emptyList(),
                    potShares = emptyList(),
                    currentBlindLevel = if (newBlindLevel) state.nextBlindLevel else state.currentBlindLevel,
                    handCount = if (newBlindLevel) 0 else state.handCount,
                    canRebuy = state.rebuyLevelsLeft > 0,
                    showdownTrigger = false,
                )
            }
        }

        is ActionRequestedEvent -> {
            _state.update { state ->
                val player = state.players.singleOrNull { it.seat == state.playerSeat }
                if (player?.seat == event.seat) {
                    if (isApplicationOnBackground()) {
                        notifyPlayer("It's your turn!", "You have pending actions")
                    }

                    var bettingSize: BettingSize? = null
                    event.availableActions.forEach { action ->
                        when (action) {
                            is PlayerHandAvailableAction.Bet -> {
                                bettingSize = BettingSize(
                                    min = action.min + player.bettingStack,
                                    max = action.max + player.bettingStack,
                                    amount = action.min + player.bettingStack,
                                )
                            }

                            is PlayerHandAvailableAction.Raise -> {
                                bettingSize = BettingSize(
                                    min = action.min + player.bettingStack,
                                    max = action.max + player.bettingStack,
                                    amount = action.min + player.bettingStack,
                                )
                            }

                            else -> {}
                        }
                    }
                    state.copy(
                        playerAtTurnSeat = event.seat,
                        actionTime = state.actionTime.copy(offset = event.instant, timeout = event.timeout),
                        playerAvailableActions = event.availableActions.toList(),
                        playerActionRequestId = event.requestId,
                        bettingSize = bettingSize,
                    )
                } else {
                    state.copy(
                        playerAtTurnSeat = event.seat,
                        actionTime = state.actionTime.copy(offset = event.instant, timeout = event.timeout),
                    )
                }
            }
        }

        is ActionPerformedEvent -> {
            _state.update { state ->
                val folded = event.action is PlayerHandAction.Fold
                val players = state.players.map { player ->
                    if (player.seat == event.seat) {
                        player.copy(
                            folded = folded,
                            remainingStack = event.remainingStack,
                            bettingStack = event.bettingStack,
                            action = event.action,
                            away = if (folded && state.pendingAwayPlayers.contains(event.seat)) true
                            else player.away,
                        )
                    } else {
                        player
                    }
                }
                state.copy(
                    players = players,
                    lastPlayerActionPerformed = event.action,
                    lastPlayerWentAllIn = event.remainingStack == 0.toFixed2(),
                    lastBettingStack = maxOf(state.lastBettingStack, event.bettingStack),
                    totalPotSize = event.totalPotSize,
                    playerAtTurnSeat = -1,
                    playerAvailableActions = emptyList(),
                    playerActionRequestId = null,
                    actionTime = PokerViewState.ActionTime(Instant.DISTANT_PAST, Duration.ZERO, null),
                    bettingSize = null,
                    pendingAwayPlayers = if (folded) state.pendingAwayPlayers.filter { it != event.seat }
                    else state.pendingAwayPlayers,
                )
            }
        }

        is BlindsPostedEvent -> {}

        is PlayerBlindMissedEvent -> {}

        is RoundStartedEvent -> {
            _state.update { state ->
                val players = state.players.map { player ->
                    event.remainingStacks.singleOrNull { it.seat == player.seat }
                        ?.let { result ->
                            player.copy(
                                bettingStack = 0.toFixed2(),
                                action = null,
                                remainingStack = result.stack,
                            )
                        }
                        ?: player.copy(
                            bettingStack = 0.toFixed2(),
                            action = null,
                        )
                }
                state.copy(
                    roundEnded = false,
                    lastBettingStack = 0.toFixed2(),
                    players = players,
                )
            }
        }

        is PlayerCardsDealtEvent -> {
            _state.update {
                it.copy(
                    players = it.players.map { player ->
                        if (event.seat == player.seat) {
                            player.copy(holeCards = event.cards)
                        } else {
                            player
                        }
                    },
                )
            }
        }

        is PlayerCardsRevealedEvent -> {
            _state.update {
                it.copy(
                    players = it.players.map { player ->
                        if (event.seat == player.seat) {
                            player.copy(
                                showdown = true,
                                holeCards = event.cards,
                            )
                        } else {
                            player
                        }
                    },
                    showdownTrigger = true,
                )
            }
        }

        is CommunityCardsDealtEvent -> {
            _state.update { state ->
                state.copy(
                    communityCards = state.communityCards + event.cards,
                    rankedHighHand = event.highHandRanks[state.playerSeat],
                    rankedLowHand = event.lowHandRanks[state.playerSeat],
                )
            }
        }

        is RoundEndedEvent -> {
            _state.update { state ->
                var potContributions = state.potContributions
                event.potContributions.forEach { pot ->
                    val existingPot = potContributions.singleOrNull { it.seats == pot.seats }
                    potContributions = if (existingPot == null) {
                        potContributions + pot
                    } else {
                        potContributions.map { potContribution ->
                            if (potContribution.seats == pot.seats) {
                                potContribution.copy(amount = potContribution.amount + pot.amount)
                            } else {
                                potContribution
                            }
                        }
                    }
                }
                val players = state.players.map { player ->
                    event.seatResults.singleOrNull { it.seat == player.seat }
                        ?.let { result ->
                            player.copy(
                                // TODO possibly send the remaining stack in the round or hand ended
                                remainingStack = player.remainingStack + result.uncalledBetAmount,
                            )
                        }
                        ?: player
                }
                state.copy(
                    roundEnded = true,
                    potContributions = potContributions,
                    players = players,
                )
            }
        }

        is HandEndedEvent -> {
            _state.update { state ->
                val players = state.players.map { player ->
                    event.seatResults.singleOrNull { it.seat == player.seat }
                        ?.let { result ->
                            val remainingStack = player.remainingStack + result.winAmount
                            player.copy(
                                bettingStack = 0.toFixed2(),
                                action = null,
                                remainingStack = remainingStack,
                                eliminated = remainingStack == 0.toFixed2(),
                                away = if (state.pendingAwayPlayers.contains(player.seat)) true
                                else player.away,
                            )
                        }
                        ?: player
                }
                state.copy(
                    handIndex = state.handIndex + 1,
                    handCount = state.handCount + 1,
                    playingHand = false,
                    potContributions = emptyList(),
                    potShares = event.potShares,
                    players = players,
                    playerAtTurnSeat = -1,
                    lastPlayerActionPerformed = null,
                    pendingAwayPlayers = emptyList(),
                    lastHandId = event.handId,
                )
            }
        }
    }

    private fun isApplicationOnBackground(): Boolean {
        return _state.value.applicationState != Lifecycle.State.RESUMED
    }
}
