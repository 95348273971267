package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.animation.core.animate
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import kotlin.math.absoluteValue
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorHandResult
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.GamePaused
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun Board(
    device: Device,
    firstHand: Boolean = false,
    communityCards: List<DealtRankedCardPlayerView>,
    cardLayout: CardLayout,
    cardStyle: CardStyle,
    cardBackStyle: CardBackStyle,
    playingHand: Boolean = false,
    winningPlayer: Player? = null,
    winningCards: List<DealtRankedCardPlayerView> = emptyList(),
    displayHandResult: Boolean = true,
    gameEnded: Boolean = false,
    gamePaused: Boolean = false,
    verticalDisplay: Boolean = false,
    borderColor: Color = Color.LightGray,
    deal: () -> Unit = {},
) {
    var flopCards by remember(communityCards.isEmpty()) { mutableStateOf<List<DealtRankedCardPlayerView>?>(null) }
    var turnCard by remember(communityCards.isEmpty()) { mutableStateOf<DealtRankedCardPlayerView?>(null) }
    var riverCard by remember(communityCards.isEmpty()) { mutableStateOf<DealtRankedCardPlayerView?>(null) }

    var isFlopFlipped by remember(communityCards.isEmpty()) { mutableStateOf(false) }
    var isTurnFlipped by remember(communityCards.isEmpty()) { mutableStateOf(false) }
    var isRiverFlipped by remember(communityCards.isEmpty()) { mutableStateOf(false) }

    var flipRotation by remember(communityCards.size) { mutableStateOf(0f) }

    if (flopCards == null && communityCards.size >= 3) flopCards = communityCards.subList(0, 3)
    if (turnCard == null && communityCards.size >= 4) turnCard = communityCards[3]
    if (riverCard == null && communityCards.size >= 5) riverCard = communityCards[4]

    LaunchedEffect(key1 = communityCards.size) {
        if (communityCards.isNotEmpty()) {
            animate(initialValue = 0f, targetValue = 180f, animationSpec = tween(durationMillis = 750)) { value: Float, _: Float ->
                flipRotation = value
            }
        }
    }

    val cornerRadius = PokerAppTheme.dimensions.grid_1

    BoxWithConstraints(
        modifier = Modifier
            .fillMaxSize()
            .padding(PokerAppTheme.dimensions.grid_0_25)
            .clip(RoundedCornerShape(cornerRadius))
            .border(
                width = PokerAppTheme.dimensions.grid_0_25,
                color = borderColor,
                shape = RoundedCornerShape(cornerRadius),
            )
            .padding(PokerAppTheme.dimensions.grid_0_25),
        contentAlignment = Alignment.Center,
    ) {
        var padding = PokerAppTheme.dimensions.grid_6
        var cardWidth = (maxWidth - padding) / 5
        val cardHeightMultiplier = 1.40f

        if (cardWidth * cardHeightMultiplier > maxHeight) {
            cardWidth = maxHeight / cardHeightMultiplier
            padding = maxWidth - cardWidth * 5
        }

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(cardWidth * cardHeightMultiplier * 0.8f),
            contentAlignment = Alignment.Center,
        ) {
            Image(
                modifier = Modifier.fillMaxSize(),
                painter = painterResource(Res.drawable.logo_long_text_horizontal_white),
                contentDescription = null,
                contentScale = ContentScale.Fit,
                alpha = 0.7f,
            )
        }

        Row(
            modifier = Modifier.padding(PokerAppTheme.dimensions.grid_0_5),
            horizontalArrangement = Arrangement.spacedBy(padding / 6f),
        ) {
            flopCards?.forEach { dealtCard ->
                FlipCard(
                    dealtCard = dealtCard,
                    cardLayout = cardLayout,
                    cardStyle = cardStyle,
                    cardBackStyle = cardBackStyle,
                    cardWidth = cardWidth,
                    isFlipped = isFlopFlipped,
                    flipRotation = flipRotation,
                    winningCard = if (!playingHand) {
                        winningCards.isEmpty() || winningCards.singleOrNull { it == dealtCard } != null
                    } else {
                        true
                    },
                )
            }

            if (flopCards != null && flipRotation.absoluteValue == 180f) isFlopFlipped = true

            turnCard?.let { dealtCard ->
                FlipCard(
                    dealtCard = dealtCard,
                    cardLayout = cardLayout,
                    cardStyle = cardStyle,
                    cardBackStyle = cardBackStyle,
                    cardWidth = cardWidth,
                    isFlipped = isTurnFlipped,
                    flipRotation = flipRotation,
                    winningCard = if (!playingHand) {
                        winningCards.isEmpty() || winningCards.singleOrNull { it == dealtCard } != null
                    } else {
                        true
                    },
                )
            }

            if (turnCard != null && flipRotation.absoluteValue == 180f) isTurnFlipped = true

            riverCard?.let { dealtCard ->
                FlipCard(
                    dealtCard = dealtCard,
                    cardLayout = cardLayout,
                    cardStyle = cardStyle,
                    cardBackStyle = cardBackStyle,
                    cardWidth = cardWidth,
                    isFlipped = isRiverFlipped,
                    flipRotation = flipRotation,
                    winningCard = if (!playingHand) {
                        winningCards.isEmpty() || winningCards.singleOrNull { it == dealtCard } != null
                    } else {
                        true
                    },
                )
            }

            if (riverCard != null && flipRotation.absoluteValue == 180f) isRiverFlipped = true

            repeat(5 - communityCards.size) {
                Box(
                    modifier = Modifier
                        .width(cardWidth)
                        .height(cardWidth * cardHeightMultiplier),
                )
            }

        }

        if (!gameEnded && !playingHand) {
            if (!firstHand && winningPlayer != null && displayHandResult) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight(0.3f)
                        .background(colorHandResult.copy(alpha = 0.8f)),
                ) {
                    when (device) {
                        Device.Player -> {
                            DisplayHandResult(winningPlayer, PokerAppTheme.typography.titleMedium)
                        }
                        Device.Table -> {
                            if (verticalDisplay) {
                                DisplayHandResult(winningPlayer, PokerAppTheme.typography.displayMedium)
                            } else {
                                Row(
                                    modifier = Modifier.fillMaxSize(),
                                ) {
                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .weight(1f)
                                            .rotate(0f),
                                        horizontalAlignment = Alignment.CenterHorizontally,
                                        verticalArrangement = Arrangement.Center,
                                    ) {
                                        DisplayHandResult(winningPlayer, PokerAppTheme.typography.displayMedium)
                                    }
                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .weight(1f)
                                            .rotate(180f),
                                        horizontalAlignment = Alignment.CenterHorizontally,
                                        verticalArrangement = Arrangement.Center,
                                    ) {
                                        DisplayHandResult(winningPlayer, PokerAppTheme.typography.displayMedium)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (device == Device.Table) {
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .pointerInput(Unit) {
                            detectTapGestures(
                                onDoubleTap = { deal() },
                            )
                        },
                ) {
                    // Placeholder (double tap to deal)
                }
            }
        }
        if (gamePaused) {
            GamePaused(device)
        }
    }
}

@Composable
private fun DisplayHandResult(
    winningPlayer: Player?,
    textStyle: TextStyle = TextStyle(),
) {
    Row(
        modifier = Modifier
            .padding(PokerAppTheme.dimensions.grid_0_5)
            .fillMaxSize(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1, Alignment.CenterHorizontally),
    ) {
        winningPlayer?.let {
            BoxWithConstraints {
                val maxHeight = maxHeight
                if (winningPlayer.avatarUrl != null) {
                    KamelImage(
                        modifier = Modifier
                            .size(maxHeight)
                            .clip(CircleShape)
                            .align(Alignment.Center),
                        resource = { asyncPainterResource(winningPlayer.avatarUrl) },
                        contentDescription = "Avatar",
                        contentScale = ContentScale.Crop,
                        onLoading = { progress ->
                            CircularProgressIndicator(
                                progress = { progress },
                            )
                        },
                        onFailure = { _ ->
                            Image(
                                painter = painterResource(Res.drawable.avatar_profile),
                                contentDescription = "Avatar",
                                modifier = Modifier
                                    .size(maxHeight)
                                    .clip(CircleShape)
                                    .align(Alignment.Center),
                            )
                        },
                    )
                } else {
                    Image(
                        painter = if (!winningPlayer.bot) {
                            painterResource(Res.drawable.avatar_profile)
                        } else {
                            painterResource(Res.drawable.avatar_robot)
                        },
                        contentDescription = "Avatar",
                        modifier = Modifier
                            .size(maxHeight)
                            .clip(CircleShape)
                            .align(Alignment.Center),
                    )
                }
            }
            Text(
                text = (winningPlayer.screenName ?: stringResource(Res.string.loading_screen_name)) + " " + stringResource(Res.string.poker_player_notification_wins),
                style = textStyle,
                color = Color.White,
            )
        }
    }
}
