package studio.lostjoker.smartdealer.game_management.web.contract

enum class GameManagementApiErrorCode {
    Unknown,
    IdNotProvided,
    CodeNotProvided,
    GameNotFound,
    PlayerAccountNotFound,
    AvatarNotUploaded,
    EmailNotUpdated,
    ScreenNameNotSet,
    Maintenance,
}

enum class GameType {
    Sueca,
    Poker
}
