package studio.lostjoker.smartdealer.helpers

import androidx.compose.runtime.Composable
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.api_error_avatar_not_uploaded
import smartdealer.appshared.generated.resources.api_error_code_not_provided
import smartdealer.appshared.generated.resources.api_error_email_not_updated
import smartdealer.appshared.generated.resources.api_error_game_not_found
import smartdealer.appshared.generated.resources.api_error_id_not_provided
import smartdealer.appshared.generated.resources.api_error_maintenance_mode
import smartdealer.appshared.generated.resources.api_error_player_account_not_found
import smartdealer.appshared.generated.resources.api_error_screen_name_not_set
import smartdealer.appshared.generated.resources.api_error_unknown
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode

@Composable
fun getGameManagementApiErrorMessage(code: GameManagementApiErrorCode) : String {
    return when (code) {
        GameManagementApiErrorCode.Unknown -> stringResource(Res.string.api_error_unknown)
        GameManagementApiErrorCode.IdNotProvided -> stringResource(Res.string.api_error_id_not_provided)
        GameManagementApiErrorCode.CodeNotProvided -> stringResource(Res.string.api_error_code_not_provided)
        GameManagementApiErrorCode.GameNotFound -> stringResource(Res.string.api_error_game_not_found)
        GameManagementApiErrorCode.PlayerAccountNotFound -> stringResource(Res.string.api_error_player_account_not_found)
        GameManagementApiErrorCode.AvatarNotUploaded -> stringResource(Res.string.api_error_avatar_not_uploaded)
        GameManagementApiErrorCode.EmailNotUpdated -> stringResource(Res.string.api_error_email_not_updated)
        GameManagementApiErrorCode.ScreenNameNotSet -> stringResource(Res.string.api_error_screen_name_not_set)
        GameManagementApiErrorCode.Maintenance -> stringResource(Res.string.api_error_maintenance_mode)
    }
}
