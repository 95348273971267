package studio.lostjoker.smartdealer.ui.poker.join_game

import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode
import kotlin.uuid.Uuid

data class JsJoinGameState (
    val gameCode: String? = null,
    val errorMessage: String? = null,
    val errorCode: GameManagementApiErrorCode? = null,
    val gameId: Uuid? = null,
    val urlGameCode: String? = null
)
