@file:UseSerializers(
    UuidStringSerializer::class,
)

package protocol.poker

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import protocol.DealtRankedCardPlayerView
import protocol.Player
import studio.lostjoker.smartdealer.serialization.UuidStringSerializer
import kotlin.time.Duration
import kotlin.uuid.Uuid

@Serializable
sealed interface PokerClientEvent

@Serializable
data class GameStartedEvent(
    val instant: Instant,
) : PokerClientEvent

@Serializable
data class GamePausedEvent(
    val instant: Instant,
) : PokerClientEvent

@Serializable
data class GameResumedEvent(
    val instant: Instant,
) : PokerClientEvent

@Serializable
data class GameEndedEvent(
    val instant: Instant,
): PokerClientEvent

@Serializable
data class TournamentCreatedEvent(
    val hostPlayerId: String,
    val format: TournamentSettings,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
data class TournamentSettingsUpdatedEvent(
    val format: TournamentSettings,
    val settings: PokerSettings,
    val rebuyRemainingCount: Map<String, Int>,
) : PokerClientEvent

@Serializable
data class PlayerRegisteredEvent(
    val player: Player,
    val sessionId: Uuid,
) : PokerClientEvent

@Serializable
data class BlindLevelChangedEvent(
    val blindLevel: BlindLevel,
    val handCountRange: BlindLevelHandCountRange,
    val lateRegistrationLevelsLeft: Int,
    val rebuyLevelsLeft: Int,
) : PokerClientEvent

@Serializable
data class PlayerEliminatedEvent(
    val playerId: String,
    val sessionId: Uuid,
    val rank: Int,
    val prize: Fixed2,
    val rebuy: Int,
    val finalStack: Fixed2,
) : PokerClientEvent

@Serializable
data class TableCreatedEvent(
    val tableId: Uuid,
    val settings: PokerSettings,
    val straddle: Boolean,
) : PokerClientEvent

@Serializable
data class TableSettingsUpdatedEvent(
    val tableId: Uuid,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
data class PlayerSeatedEvent(
    val tableId: Uuid,
    val player: Player,
    val seat: Int,
    val stack: Fixed2,
) : PokerClientEvent

@Serializable
data class PlayerSatOutEvent(
    val tableId: Uuid,
    val seat: Int,
    val reason: SittingOutReason,
) : PokerClientEvent

@Serializable
data class PlayerSatInEvent(
    val tableId: Uuid,
    val seat: Int,
) : PokerClientEvent

@Serializable
data class PlayerRebuyCompletedEvent(
    val playerId: String,
    val amount: Fixed2,
    val rebuyRemainingCount: Int,
) : PokerClientEvent

@Serializable
data class PlayerStackUpdatedEvent(
    val tableId: Uuid,
    val playerId: String,
    val seat: Int,
    val amount: Fixed2,
    val stack: Fixed2,
) : PokerClientEvent

@Serializable
data class PlayerBlindMissedEvent(
    val tableId: Uuid,
    val seat: Int,
    val blindType: BlindType,
) : PokerClientEvent

@Serializable
data class HandStartedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val handIndex: Int, // zero-based
    val positions: Positions,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
data class RoundStartedEvent(
    val roundIndex: Int,
    val potSizeBeforeRound: Fixed2,
    val remainingStacks: List<SeatSnapshot>,
) : PokerClientEvent

@Serializable
data class ActionPerformedEvent(
    val seat: Int,
    val action: PlayerHandAction,
    val requestId: Uuid?,
    val bettingStack: Fixed2,
    val remainingStack: Fixed2,
    val totalPotSize: Fixed2,
    val auto: Boolean,
    val away: Boolean,
) : PokerClientEvent

@Serializable
data class ActionRequestedEvent(
    val requestId: Uuid,
    val seat: Int,
    val availableActions: List<PlayerHandAvailableAction>,
    val instant: Instant,
    val timeout: Duration,
) : PokerClientEvent

@Serializable
data object BlindsPostedEvent : PokerClientEvent

@Serializable
data class PlayerCardsDealtEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seat: Int,
    val cards: List<DealtRankedCardPlayerView>,
) : PokerClientEvent

@Serializable
data class PlayerCardsRevealedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seat: Int,
    val cards: List<DealtRankedCardPlayerView>,
) : PokerClientEvent

@Serializable
data class CommunityCardsDealtEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val cards: List<DealtRankedCardPlayerView>,
    val highHandRanks: Map<Int, RankedHighHand>,
    val lowHandRanks: Map<Int, RankedLowHand>,
) : PokerClientEvent

@Serializable
data class RoundEndedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val roundIndex: Int,
    val seatResults: List<SeatRoundResult>,
    val potContributions: List<Pot>,
) : PokerClientEvent

@Serializable
data class HandEndedEvent(
    val tableId: Uuid,
    val handId: Uuid,
    val seatResults: List<SeatHandResult>,
    val potShares: List<PotSharePlayerProjection>,
    val pots: List<Pot>,
) : PokerClientEvent

@Serializable
data class RingCreatedEvent(
    val hostPlayerId: String,
    val format: RingGameSettings,
    val settings: PokerSettings,
) : PokerClientEvent

@Serializable
data class PlayerBoughtInEvent(
    val player: Player,
    val sessionId: Uuid,
) : PokerClientEvent

@Serializable
data class PlayerToppedUpEvent(
    val playerId: String,
    val amount: Fixed2,
) : PokerClientEvent

@Serializable
data class RingGameSettingsUpdatedEvent(
    val format: RingGameSettings,
    val settings: PokerSettings,
) : PokerClientEvent

