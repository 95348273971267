package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.displayCutout
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.outlined.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.CenterAlignedTopAppBar
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_settings_ring
import smartdealer.appshared.generated.resources.poker_settings_update
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.BlindProgression
import studio.lostjoker.smartdealer.domain.poker.model.RingBuyInSettings
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.ui.common.dialog.UpdateGameSettingsConfirmationDialog
import studio.lostjoker.smartdealer.ui.components.TopAppBarActionButton
import studio.lostjoker.smartdealer.ui.poker.common.helpers.findRingGameNearestBlind
import studio.lostjoker.smartdealer.ui.poker.common.helpers.ringGameBlindStructure
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.enum.ActionTimeout
import studio.lostjoker.smartdealer.ui.poker.enum.RingGameStake
import studio.lostjoker.smartdealer.ui.poker.host_game.RingGameBlindSettings
import studio.lostjoker.smartdealer.ui.poker.host_game.RingGameBuyInRange
import studio.lostjoker.smartdealer.ui.poker.host_game.components.ActionTimeoutSelector
import studio.lostjoker.smartdealer.ui.poker.host_game.components.RingGameBlindSelector
import studio.lostjoker.smartdealer.ui.poker.host_game.components.RingGameBuyInRangeSelector
import studio.lostjoker.smartdealer.ui.poker.host_game.components.TableSizeSelector
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import studio.lostjoker.smartdealer.ui.poker.host_game.RingGameSettings as StateRingGameSettings

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun RingGameSettings(
    players: List<Player>,
    ringGameSettings: RingGameSettings,
    pokerSettings: PokerSettings,
    onUpdateRingGameSettings: (ringGameSettings: RingGameSettings, pokerSettings: PokerSettings) -> Unit = { _, _ -> },
    onCloseRingGameSettings: () -> Unit = {},
) {
    var openUpdateGameSettingsConfirmationDialog by remember { mutableStateOf(false) }

    val blindSettings = when (val blindProgression = pokerSettings.blindProgression) {
        is BlindProgression.HandCount -> throw IllegalStateException("Invalid blind progression for Ring Game")
        is BlindProgression.TimeSlot -> throw IllegalStateException("Invalid blind progression for Ring Game")
        is BlindProgression.Static -> RingGameBlindSettings(
            smallBlind = blindProgression.blindLevel.smallBlind.asDouble(),
            bigBlind = blindProgression.blindLevel.bigBlind.asDouble(),
        )
    }

    val buyInRange = when (val buyInSettings = ringGameSettings.buyIn) {
        is RingBuyInSettings.ImplicitBuyIn -> TODO()
        is RingBuyInSettings.RangeBuyIn -> RingGameBuyInRange(
            minBuyIn = buyInSettings.minBuyIn.asDouble(),
            maxBuyIn = buyInSettings.maxBuyIn.asDouble(),
        )
    }

    val stake = ringGameBlindStructure
        .mapNotNull { (key, value) -> if (blindSettings.bigBlind <= value.last().bigBlind) key else null }
        .first()

    val currentState = RingGameSettingsState(
        tableSize = pokerSettings.tableSize,
        actionTimeout = ActionTimeout.entries.single { it.seconds == pokerSettings.actionTimeout },
        blindSettings = blindSettings,
        blindSettingsSliderPosition = (ringGameBlindStructure[stake]!!.indexOf(blindSettings) + 1).toFloat(),
        buyInRange = buyInRange,
        buyInRangeSliderPosition = (buyInRange.minBuyIn / blindSettings.bigBlind).toFloat() / 10f..(buyInRange.maxBuyIn / blindSettings.bigBlind).toFloat() / 10f,
        stake = stake,
    )

    var newState by remember { mutableStateOf(currentState) }

    Column(
        modifier = Modifier
            .background(PokerAppTheme.colors.background)
            .fillMaxSize(),
    ) {
        CenterAlignedTopAppBar(
            windowInsets = WindowInsets.displayCutout,
            title = {
                Text(
                    style = PokerAppTheme.typography.titleLarge,
                    text = stringResource(Res.string.poker_settings_ring),
                )
            },
            navigationIcon = {
                TopAppBarActionButton(
                    imageVector = Icons.AutoMirrored.Outlined.ArrowBack,
                    description = "Return",
                ) {
                    onCloseRingGameSettings()
                }
            },
            actions = {

            },
        )
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(vertical = 8.dp),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Column(
                modifier = Modifier.fillMaxSize().weight(1f),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(horizontal = 16.dp)
                        .verticalScroll(rememberScrollState()),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1_5, Alignment.Top),
                ) {
                    Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_0_5))

                    TableSizeSelector(newState.tableSize, players.maxOf { it.seat } + 1, 10) { size ->
                        newState = newState.copy(tableSize = size)
                    }

                    ActionTimeoutSelector(
                        actionTimeout = newState.actionTimeout,
                        onChangeActionTimeout = { actionTimeout ->
                            newState = newState.copy(actionTimeout = actionTimeout)
                        }
                    )

                    val stateRingGameSettings = StateRingGameSettings().copy(
                        blindSettings = newState.blindSettings,
                        blindSettingsSliderPosition = newState.blindSettingsSliderPosition,
                        buyInRange = newState.buyInRange,
                        buyInRangeSliderPosition = newState.buyInRangeSliderPosition,
                        stake = newState.stake,
                    )

                    RingGameBlindSelector(
                        ringGameSettings = stateRingGameSettings,
                        onChangeRingGameStake = { stake ->
                            val newBlinds = findRingGameNearestBlind(stake, 1)
                            newState = newState.copy(
                                blindSettings = newState.blindSettings.copy(
                                    ante = newBlinds.ante,
                                    smallBlind = newBlinds.smallBlind,
                                    bigBlind = newBlinds.bigBlind,
                                ),
                                blindSettingsSliderPosition = 1f,
                                buyInRange = newState.buyInRange.copy(
                                    minBuyIn = newBlinds.bigBlind * 20f,
                                    maxBuyIn = newBlinds.bigBlind * 100f,
                                ),
                                buyInRangeSliderPosition = 2f..10f,
                                stake = stake,
                            )
                        },
                        onChangeRingGameBlinds = { sliderPosition ->
                            val newBlinds = findRingGameNearestBlind(newState.stake, sliderPosition.toInt())
                            newState = newState.copy(
                                blindSettings = newState.blindSettings.copy(
                                    ante = newBlinds.ante,
                                    smallBlind = newBlinds.smallBlind,
                                    bigBlind = newBlinds.bigBlind,
                                ),
                                blindSettingsSliderPosition = sliderPosition,
                                buyInRange = newState.buyInRange.copy(
                                    minBuyIn = newBlinds.bigBlind * 20f,
                                    maxBuyIn = newBlinds.bigBlind * 100f,
                                ),
                                buyInRangeSliderPosition = 2f..10f,
                            )
                        },
                    )

                    RingGameBuyInRangeSelector(
                        ringGameSettings = stateRingGameSettings,
                        onChangeRingGameBuyInRange = { buyInRange ->
                            newState = newState.copy(
                                buyInRange = newState.buyInRange.copy(
                                    minBuyIn = newState.blindSettings.bigBlind * 10f * buyInRange.start.toInt(),
                                    maxBuyIn = newState.blindSettings.bigBlind * 10f * buyInRange.endInclusive.toInt(),
                                ),
                                buyInRangeSliderPosition = buyInRange,
                            )
                        },
                    )

                }
            }
            Button(
                modifier = Modifier
                    .fillMaxWidth(.8f)
                    .padding(bottom = PokerAppTheme.dimensions.grid_1_5),
                onClick = { openUpdateGameSettingsConfirmationDialog = true },
                enabled = currentState != newState,
            ) {
                Text(
                    text = stringResource(Res.string.poker_settings_update),
                    style = PokerAppTheme.typography.titleMedium,
                )
            }
        }

        if (openUpdateGameSettingsConfirmationDialog) {
            UpdateGameSettingsConfirmationDialog(
                closeDialog = { openUpdateGameSettingsConfirmationDialog = false },
                updateGameSettings = {
                    // TODO: Include FB Analytics
                    onUpdateRingGameSettings(
                        ringGameSettings.copy(
                            allowStraddle = false,
                            buyIn = RingBuyInSettings.RangeBuyIn(
                                newState.buyInRange.minBuyIn.toFixed2(),
                                newState.buyInRange.maxBuyIn.toFixed2(),
                            ),
                        ),
                        pokerSettings.copy(
                            tableSize = newState.tableSize,
                            actionTimeout = newState.actionTimeout.seconds,
                            blindProgression = BlindProgression.Static(
                                blindLevel = studio.lostjoker.smartdealer.domain.poker.model.BlindLevel(
                                    newState.blindSettings.ante?.toFixed2() ?: 0.toFixed2(),
                                    newState.blindSettings.smallBlind.toFixed2(),
                                    newState.blindSettings.bigBlind.toFixed2(),
                                ),
                            ),
                        ),
                    )
                    onCloseRingGameSettings()
                },
            )
        }
    }
}

data class RingGameSettingsState(
    val tableSize: Int,
    val actionTimeout: ActionTimeout,
    val blindSettings: RingGameBlindSettings,
    val blindSettingsSliderPosition: Float,
    val buyInRange: RingGameBuyInRange,
    val buyInRangeSliderPosition: ClosedFloatingPointRange<Float> = 0f..100f,
    val stake: RingGameStake,
)
