@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package smartdealer.appshared.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.PluralStringResource

private object CommonMainPlurals0 {
  public val poker_devices_blind_level_up_duration_hands: PluralStringResource by 
      lazy { init_poker_devices_blind_level_up_duration_hands() }

  public val poker_settings_bots_number_of_bots: PluralStringResource by 
      lazy { init_poker_settings_bots_number_of_bots() }

  public val poker_settings_rebuy_limit_times: PluralStringResource by 
      lazy { init_poker_settings_rebuy_limit_times() }
}

@InternalResourceApi
internal fun _collectCommonMainPlurals0Resources(map: MutableMap<String, PluralStringResource>) {
  map.put("poker_devices_blind_level_up_duration_hands",
      CommonMainPlurals0.poker_devices_blind_level_up_duration_hands)
  map.put("poker_settings_bots_number_of_bots",
      CommonMainPlurals0.poker_settings_bots_number_of_bots)
  map.put("poker_settings_rebuy_limit_times", CommonMainPlurals0.poker_settings_rebuy_limit_times)
}

internal val Res.plurals.poker_devices_blind_level_up_duration_hands: PluralStringResource
  get() = CommonMainPlurals0.poker_devices_blind_level_up_duration_hands

private fun init_poker_devices_blind_level_up_duration_hands(): PluralStringResource =
    org.jetbrains.compose.resources.PluralStringResource(
  "plurals:poker_devices_blind_level_up_duration_hands",
    "poker_devices_blind_level_up_duration_hands",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 10,
    91),
    )
)

internal val Res.plurals.poker_settings_bots_number_of_bots: PluralStringResource
  get() = CommonMainPlurals0.poker_settings_bots_number_of_bots

private fun init_poker_settings_bots_number_of_bots(): PluralStringResource =
    org.jetbrains.compose.resources.PluralStringResource(
  "plurals:poker_settings_bots_number_of_bots", "poker_settings_bots_number_of_bots",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    102, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 102,
    78),
    )
)

internal val Res.plurals.poker_settings_rebuy_limit_times: PluralStringResource
  get() = CommonMainPlurals0.poker_settings_rebuy_limit_times

private fun init_poker_settings_rebuy_limit_times(): PluralStringResource =
    org.jetbrains.compose.resources.PluralStringResource(
  "plurals:poker_settings_rebuy_limit_times", "poker_settings_rebuy_limit_times",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    181, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 181,
    80),
    )
)
