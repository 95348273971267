package studio.lostjoker.smartdealer.helpers

import androidx.compose.material3.windowsizeclass.WindowHeightSizeClass
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.unit.dp
import kotlinx.browser.window
import org.w3c.dom.events.Event

object WebWindowWidthSizeClass {
    val Compact = WindowWidthSizeClass.Compact
    val Medium = WindowWidthSizeClass.Medium
    val Expanded = WindowWidthSizeClass.Expanded
}

object WebWindowHeightSizeClass {
    val Compact = WindowHeightSizeClass.Compact
    val Medium = WindowHeightSizeClass.Medium
    val Expanded = WindowHeightSizeClass.Expanded
}

@Composable
fun calculateWebWindowSizeClass(): Pair<WindowWidthSizeClass, WindowHeightSizeClass> {
    var windowWidth by remember { mutableStateOf(window.innerWidth) }
    var windowHeight by remember { mutableStateOf(window.innerHeight) }

    DisposableEffect(Unit) {
        val resizeListener = { _: Event ->
            windowWidth = window.innerWidth
            windowHeight = window.innerHeight
        }

        window.addEventListener("resize", resizeListener)

        onDispose {
            window.removeEventListener("resize", resizeListener)
        }
    }

    val widthDp = (windowWidth / 1.5).toInt().dp
    val heightDp = (windowHeight / 1.5).toInt().dp

    val widthSizeClass = when {
        widthDp < 600.dp -> WebWindowWidthSizeClass.Compact
        widthDp < 840.dp -> WebWindowWidthSizeClass.Medium
        else -> WebWindowWidthSizeClass.Expanded
    }

    val heightSizeClass = when {
        heightDp < 480.dp -> WebWindowHeightSizeClass.Compact
        heightDp < 900.dp -> WebWindowHeightSizeClass.Medium
        else -> WebWindowHeightSizeClass.Expanded
    }

    return Pair(widthSizeClass, heightSizeClass)
}
