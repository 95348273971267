package studio.lostjoker.smartdealer.ui.poker.devices.common.state

import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle
import studio.lostjoker.smartdealer.ui.poker.enum.TableFelt
import studio.lostjoker.smartdealer.ui.poker.enum.TableLayout

data class Settings (
    val tableLayout: TableLayout = TableLayout.Live,
    val tableFelt: TableFelt = TableFelt.Green,
    val cardLayout: CardLayout = CardLayout.SingleSidedSimple,
    val cardStyle: CardStyle = CardStyle.Original,
    val cardBackStyle: CardBackStyle = CardBackStyle.Red,
    val verticalDisplay: Boolean = false,
    val gameplayTutorial: Boolean = false,
    val terminateRingGameReminder: Boolean = false,
    val soundEnabled: Boolean = false,
)
