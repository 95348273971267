@file:AutoConverters(
    [
        AutoConverter(domainClass = TournamentCreatedEvent::class, protocolClass = protocol.poker.TournamentCreatedEvent::class),
        AutoConverter(domainClass = TournamentSettingsUpdatedEvent::class, protocolClass = protocol.poker.TournamentSettingsUpdatedEvent::class),
        AutoConverter(domainClass = PlayerRegisteredEvent::class, protocolClass = protocol.poker.PlayerRegisteredEvent::class),
        AutoConverter(domainClass = PlayerRebuyCompletedEvent::class, protocolClass = protocol.poker.PlayerRebuyCompletedEvent::class),
        AutoConverter(domainClass = PlayerStackUpdatedEvent::class, protocolClass = protocol.poker.PlayerStackUpdatedEvent::class),
        AutoConverter(domainClass = GameStartedEvent::class, protocolClass = protocol.poker.GameStartedEvent::class),
        AutoConverter(domainClass = GamePausedEvent::class, protocolClass = protocol.poker.GamePausedEvent::class),
        AutoConverter(domainClass = GameResumedEvent::class, protocolClass = protocol.poker.GameResumedEvent::class),
        AutoConverter(domainClass = BlindLevelChangedEvent::class, protocolClass = protocol.poker.BlindLevelChangedEvent::class),
        AutoConverter(domainClass = PlayerEliminatedEvent::class, protocolClass = protocol.poker.PlayerEliminatedEvent::class),
        AutoConverter(domainClass = GameEndedEvent::class, protocolClass = protocol.poker.GameEndedEvent::class),
        AutoConverter(domainClass = TableCreatedEvent::class, protocolClass = protocol.poker.TableCreatedEvent::class),
        AutoConverter(domainClass = TableSettingsUpdatedEvent::class, protocolClass = protocol.poker.TableSettingsUpdatedEvent::class),
        AutoConverter(domainClass = PlayerSeatedEvent::class, protocolClass = protocol.poker.PlayerSeatedEvent::class),
        AutoConverter(domainClass = PlayerSatOutEvent::class, protocolClass = protocol.poker.PlayerSatOutEvent::class),
        AutoConverter(domainClass = PlayerSatInEvent::class, protocolClass = protocol.poker.PlayerSatInEvent::class),
        AutoConverter(domainClass = HandStartedEvent::class, protocolClass = protocol.poker.HandStartedEvent::class),
        AutoConverter(domainClass = RoundStartedEvent::class, protocolClass = protocol.poker.RoundStartedEvent::class),
        AutoConverter(domainClass = ActionRequestedEvent::class, protocolClass = protocol.poker.ActionRequestedEvent::class),
        AutoConverter(domainClass = ActionPerformedEvent::class, protocolClass = protocol.poker.ActionPerformedEvent::class),
        AutoConverter(domainClass = BlindsPostedEvent::class, protocolClass = protocol.poker.BlindsPostedEvent::class),
        AutoConverter(domainClass = PlayerCardsDealtEvent::class, protocolClass = protocol.poker.PlayerCardsDealtEvent::class),
        AutoConverter(domainClass = PlayerCardsRevealedEvent::class, protocolClass = protocol.poker.PlayerCardsRevealedEvent::class),
        AutoConverter(domainClass = CommunityCardsDealtEvent::class, protocolClass = protocol.poker.CommunityCardsDealtEvent::class),
        AutoConverter(domainClass = RoundEndedEvent::class, protocolClass = protocol.poker.RoundEndedEvent::class),
        AutoConverter(domainClass = HandEndedEvent::class, protocolClass = protocol.poker.HandEndedEvent::class),
        AutoConverter(domainClass = RingCreatedEvent::class, protocolClass = protocol.poker.RingCreatedEvent::class),
        AutoConverter(domainClass = PlayerBoughtInEvent::class, protocolClass = protocol.poker.PlayerBoughtInEvent::class),
        AutoConverter(domainClass = PlayerToppedUpEvent::class, protocolClass = protocol.poker.PlayerToppedUpEvent::class),
        AutoConverter(domainClass = RingGameSettingsUpdatedEvent::class, protocolClass = protocol.poker.RingGameSettingsUpdatedEvent::class),
        AutoConverter(domainClass = PlayerBlindMissedEvent::class, protocolClass = protocol.poker.PlayerBlindMissedEvent::class),
    ],
)

package studio.lostjoker.smartdealer.gameplay.protocol.converter


import studio.lostjoker.smartdealer.domain.poker.game.projection.*
import studio.lostjoker.smartdealer.contract.annotation.AutoConverter
import studio.lostjoker.smartdealer.contract.annotation.AutoConverters

fun PokerClientEvent.toProtocol(): protocol.poker.PokerClientEvent = when (this) {
    is TournamentCreatedEvent -> toProtocol()
    is TournamentSettingsUpdatedEvent -> toProtocol()
    is PlayerRegisteredEvent -> toProtocol()
    is GameStartedEvent -> toProtocol()
    is GamePausedEvent -> toProtocol()
    is GameResumedEvent -> toProtocol()
    is BlindLevelChangedEvent -> toProtocol()
    is PlayerEliminatedEvent -> toProtocol()
    is GameEndedEvent -> toProtocol()
    is TableCreatedEvent -> toProtocol()
    is TableSettingsUpdatedEvent -> toProtocol()
    is PlayerSeatedEvent -> toProtocol()
    is PlayerSatOutEvent -> toProtocol()
    is PlayerSatInEvent -> toProtocol()
    is PlayerRebuyCompletedEvent -> toProtocol()
    is PlayerStackUpdatedEvent -> toProtocol()
    is HandStartedEvent -> toProtocol()
    is RoundStartedEvent -> toProtocol()
    is ActionRequestedEvent -> toProtocol()
    is ActionPerformedEvent -> toProtocol()
    is BlindsPostedEvent -> toProtocol()
    is PlayerCardsDealtEvent -> toProtocol()
    is PlayerCardsRevealedEvent -> toProtocol()
    is CommunityCardsDealtEvent -> toProtocol()
    is RoundEndedEvent -> toProtocol()
    is HandEndedEvent -> toProtocol()
    is RingCreatedEvent -> toProtocol()
    is PlayerBoughtInEvent -> toProtocol()
    is PlayerToppedUpEvent -> toProtocol()
    is RingGameSettingsUpdatedEvent -> toProtocol()
    is PlayerBlindMissedEvent -> toProtocol()
}

fun protocol.poker.PokerClientEvent.toDomain(): PokerClientEvent = when (this) {
    is protocol.poker.TournamentCreatedEvent -> toDomain()
    is protocol.poker.TournamentSettingsUpdatedEvent -> toDomain()
    is protocol.poker.PlayerRegisteredEvent -> toDomain()
    is protocol.poker.GameStartedEvent -> toDomain()
    is protocol.poker.GamePausedEvent -> toDomain()
    is protocol.poker.GameResumedEvent -> toDomain()
    is protocol.poker.BlindLevelChangedEvent -> toDomain()
    is protocol.poker.PlayerEliminatedEvent -> toDomain()
    is protocol.poker.GameEndedEvent -> toDomain()
    is protocol.poker.TableCreatedEvent -> toDomain()
    is protocol.poker.TableSettingsUpdatedEvent -> toDomain()
    is protocol.poker.PlayerSeatedEvent -> toDomain()
    is protocol.poker.PlayerSatOutEvent -> toDomain()
    is protocol.poker.PlayerSatInEvent -> toDomain()
    is protocol.poker.PlayerRebuyCompletedEvent -> toDomain()
    is protocol.poker.PlayerStackUpdatedEvent -> toDomain()
    is protocol.poker.HandStartedEvent -> toDomain()
    is protocol.poker.RoundStartedEvent -> toDomain()
    is protocol.poker.ActionRequestedEvent -> toDomain()
    is protocol.poker.ActionPerformedEvent -> toDomain()
    is protocol.poker.BlindsPostedEvent -> toDomain()
    is protocol.poker.PlayerCardsDealtEvent -> toDomain()
    is protocol.poker.PlayerCardsRevealedEvent -> toDomain()
    is protocol.poker.CommunityCardsDealtEvent -> toDomain()
    is protocol.poker.RoundEndedEvent -> toDomain()
    is protocol.poker.HandEndedEvent -> toDomain()
    is protocol.poker.RingCreatedEvent -> toDomain()
    is protocol.poker.PlayerBoughtInEvent -> toDomain()
    is protocol.poker.PlayerToppedUpEvent -> toDomain()
    is protocol.poker.RingGameSettingsUpdatedEvent -> toDomain()
    is protocol.poker.PlayerBlindMissedEvent -> toDomain()
}
